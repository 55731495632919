import { FormField } from "_/components/form-field";
import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 1.5rem 1.625rem 3.75rem 1.625rem;
`;

export const Content = styled.div`
  margin-top: 1.375rem;
`;

export const FilterBar = styled.div`
  display: flex;
  gap: 1.5rem;
  padding: 1rem 0.25rem 0;
  align-items: center;
`;

export const FilterBarItem = styled.div`
  display: flex;
  place-items: center;
  gap: 0.5rem;

  ${FormField} {
    margin: 0;
    width: auto;
  }
`;

export const Label = styled.label`
  color: ${(p) => p.theme.text.muted};
  font-size: 0.875rem;
`;

export const ThermalDataWrapper = styled.div`
  display: flex;
`;

export const ThermalsReading = styled.div`
  margin-right: 1rem;
  display: flex;
  align-items: center;
  background-color: ${(p) => p.theme.background.secondary};
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
`;

export const SensorLabel = styled.span`
  color: ${(p) => p.theme.text.muted};
  text-transform: uppercase;
  font-size: 0.625rem;
  margin-right: 0.5rem;
`;

export const SensorTemp = styled.span``;
export const SensorTarget = styled.span`
  color: ${(p) => p.theme.text.muted};
`;

export const LogMessage = styled.span`
  font-family: monospace;
  font-size: 0.875rem;
`;

export const StateChange = styled.div`
  display: flex;
  position: relative;

  .content {
    position: absolute;
    background-color: ${(p) => p.theme.background.primary};
  }
`;

export const RawJson = styled.pre`
  font-family: monospace;
  font-size: 0.75rem;
`;
