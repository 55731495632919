import React, { Fragment } from "react";

import * as S from "./styled";

/**
 * Returns a boolean array corresponding to each character in the source string.
 * If a character is part of a match of any of the target words, then true will
 * be returned at that index. Otherwise false will be returned.
 */
function getMatchMap(text: string, matchWords: string[]): boolean[] {
  const lowerText = text.toLowerCase();
  const lowerMatchWords = matchWords.map((w) => w.toLowerCase());
  const map = Array<boolean>(lowerText.length).fill(false);

  for (let i = 0; i < lowerText.length; i++) {
    for (const w of lowerMatchWords) {
      if (lowerText.slice(i).startsWith(w)) {
        for (let j = 0; j < w.length; j++) {
          map[i + j] = true;
        }
      }
    }
  }

  return map;
}

interface TokenInfo {
  token: string;
  isMatch: boolean;
}

function tokenize(sourceText: string, matchWords: string[]): TokenInfo[] {
  const matchMap = getMatchMap(sourceText, matchWords);
  const tokens: TokenInfo[] = [];
  let lastWasMatch: boolean | undefined = undefined;

  for (let i = 0; i < sourceText.length; i++) {
    if (matchMap[i] === lastWasMatch) {
      tokens[tokens.length - 1].token += sourceText[i];
    } else {
      // new token
      lastWasMatch = matchMap[i];
      tokens.push({ token: sourceText[i], isMatch: lastWasMatch });
    }
  }

  return tokens;
}

interface HighlightProps {
  text: string;
  query: string | undefined;
}

export function Highlight({ text, query }: HighlightProps) {
  if (!query) {
    return text;
  }

  const matchWords = query
    .toLowerCase()
    .split(/\s+/gi)
    .filter((s) => s.length > 0);

  if (!matchWords || !matchWords.length) {
    return text;
  }

  const tokens = tokenize(text, matchWords).map((t, i) => (
    <Fragment key={i}>
      {t.isMatch ? <S.Highlight>{t.token}</S.Highlight> : t.token}
    </Fragment>
  ));

  // Wrapped in a span to preserve word wraps and other weird text
  // effects that might be broken by parent component styles.
  return <span>{tokens}</span>;
}
