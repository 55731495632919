import React, { ReactElement, useState } from "react";
import { t } from "@lingui/macro";

import { H3 } from "_/components/typography";
import { LogoSquare } from "_/components/logo";
import { Button } from "_/components/button";
import { testPermissions } from "_/components/permission-test";
import { useMachineAuthorize } from "_/data/machines";
import { useQueryParams } from "_/components/router";
import { Select } from "_/components/select";

import { machineUrls } from "_/routes";

import { OrganizationWithRbac, useMemberOrgs } from "_/data/orgs";

import { useSetLocationAndOrg } from "_/hooks/useSetLocationAndOrg";

import { Uuid } from "_/types";

import * as S from "./styled";
import { UserCodeInput } from "./user-code-input";

export const MachineAuthorize = (): ReactElement => {
  const { data: orgs } = useMemberOrgs();
  const setLocationAndOrg = useSetLocationAndOrg();
  const authorizeMachine = useMachineAuthorize();
  const search = useQueryParams();
  const [userCode, setUserCode] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState(
    undefined as Uuid | undefined
  );

  if (!orgs.length) {
    return <></>;
  }

  const orgsWithPermission = orgs.filter((org: OrganizationWithRbac) => {
    return testPermissions(
      ["machines/write", "apiKeys/create"],
      org.permissions
    );
  });

  const orgOptions = orgsWithPermission.map((org) => {
    return {
      label: org.name,
      value: org.id,
    };
  });

  const submit = async () => {
    setIsDirty(true);
    const orgId =
      orgsWithPermission.length > 1 ? selectedOrgId : orgsWithPermission[0].id;

    if (!orgId) return;

    const authGrant = await authorizeMachine.mutateAsync({
      userCode,
      orgId,
    });

    if (authGrant.authorizedMachine) {
      // Redirect to the machine detail
      const machineUrl = machineUrls.index(authGrant.authorizedMachine);
      setLocationAndOrg({ orgId, url: machineUrl });
    }
  };

  // We roll our own simple validation message support below, because this form
  // was a poor fit for our existing validation support that's used in other
  // forms, for a few reasons:
  // * Normally validation messages are shown underneath each form field, but
  //   this form only has two controls and they're horizontally centered on the
  //   page, so it was more natural to show the error message also centered, in
  //   between the two controls.
  // * The code entry control is a custom control so it would have needed a
  //   custom validation message location anyways.
  // * I didn't want to show two messages at once; I thought that'd be
  //   confusing.
  const errorMessage =
    orgsWithPermission.length > 1 && isDirty && !selectedOrgId
      ? t`components.machine-authorize.error-no-org`
      : authorizeMachine.error
        ? t`components.machine-authorize.error`
        : null;

  const content =
    orgsWithPermission.length > 0 ? (
      <>
        <H3>{t`components.machine-authorize.title`}</H3>
        <S.Description>
          {t`components.machine-authorize.description`}
        </S.Description>
        <UserCodeInput
          defaultValue={search.code}
          onChange={setUserCode}
          onSubmit={submit}
        />
        <S.MutedText>{t`components.machine-authorize.code-warning`}</S.MutedText>
        {errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
        <S.OrgWrapper>
          <S.Label>{t`components.machine-authorize.organization`}:</S.Label>
          <div>
            {orgsWithPermission.length > 1 ? (
              <Select
                options={orgOptions}
                value={selectedOrgId}
                isSearchable={false}
                onChange={setSelectedOrgId}
              />
            ) : (
              <S.OrgName>{orgsWithPermission[0].name}</S.OrgName>
            )}
          </div>
        </S.OrgWrapper>
        <Button
          onClick={submit}
          disabled={userCode.length < 8}
        >{t`components.machine-authorize.label`}</Button>
      </>
    ) : (
      <>
        <H3>{t`components.machine-authorize.missing-perms-title`}</H3>
        <S.Description>
          {t`components.machine-authorize.missing-perms-description`}
        </S.Description>
      </>
    );

  return (
    <S.Wrapper>
      <S.Main>
        <LogoSquare />
        {content}
      </S.Main>
    </S.Wrapper>
  );
};
