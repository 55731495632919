import styled from "styled-components";

import { LogoFullSize, LogoSquare } from "_/components/logo";
import { Icon as _Icon } from "_/components/icon";
import { FormField } from "_/components/form-field";

const BREAKPOINT = 768;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100vw;
  padding: 12px 14px;

  background-color: ${(p) => p.theme.header.background};

  @media (max-width: ${BREAKPOINT}px) {
    padding: 0 14px 0 0;
  }
`;

export const TitleLogoFull = styled(LogoFullSize)`
  margin: 0 auto 0 0;
  cursor: pointer;
  line-height: 0;

  @media (max-width: ${BREAKPOINT}px) {
    display: none;
  }
`;

export const TitleLogoSquare = styled(LogoSquare)`
  margin: 0 auto 0 0;
  cursor: pointer;
  line-height: 0;

  @media (min-width: ${BREAKPOINT}px) {
    display: none;
  }
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const SelectWrapper = styled.div`
  ${FormField} {
    margin-bottom: 0;
  }

  & .react-select__control {
    background-color: transparent;
    cursor: pointer;
  }
  & .react-select__option {
    cursor: pointer;
  }
  & .react-select__control:not(:hover) {
    background-color: transparent;
    color: ${(p) => p.theme.text.muted};
    & .react-select__dropdown-indicator {
      color: ${(p) => p.theme.text.muted};
    }
  }
  & .react-select__control--is-focused {
    color: ${(p) => p.theme.input.default.text};
    & .react-select__dropdown-indicator {
      color: ${(p) => p.theme.input.default.text};
    }
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 0 3em;

  @media (max-width: ${BREAKPOINT}px) {
    padding: 0 1em;
  }
`;

export const UserInfoContainer = styled.div`
  cursor: pointer;
  display: flex;
  gap: 10px;
  color: ${(p) => p.theme.text.default};
  margin-right: 8px;

  font-size: 1rem;

  @media (max-width: ${BREAKPOINT}px) {
    font-size: 0.875rem;
  }
`;

export const UserName = styled.span`
  margin: auto;
  font-size: 0.875em;

  @media (max-width: ${BREAKPOINT}px) {
    display: none;
  }
`;

export const DownIcon = styled(_Icon)`
  margin: auto;
  margin-left: -0.6rem;
  font-size: 1.2rem;
`;
