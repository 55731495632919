import React, {
  ReactElement,
  ReactNode,
  useMemo,
  useRef,
  useState,
} from "react";
import { animated, useSpring } from "@react-spring/web";
import { t } from "@lingui/macro";
import * as provider from "@mdx-js/react";
import * as runtime from "react/jsx-runtime";
import { evaluateSync, EvaluateOptions } from "@mdx-js/mdx";

import { DocsImg } from "_/components/docs-view/docs-img";
import { LoadingSpinner, LoadingWrapper } from "_/components/docs-view/styled";

import * as S from "./styled";

const HOMEPAGE_CONTENT_SLUG = "homepage-content";

/**
 * Temporary hard-coded homepage markdown content.
 *
 * In a future PR we'll load this content from some other source, like a DB
 * table or a page in the docs.
 * */
const markdownContent = `
<Box>
### What's New

#### Basis
* Improved Machine Configuration and Events tabs
* Self-service machine setup
* Integrated, searchable documentation
* French translation
* Clearer indication of what materials can be simulated
* Handle larger prints in Optimize and Analyze
<More>
* Improve progress tracking for prints and simulations
* Self-service user invites
* Improved email notifications
* Create a new revision from simulation-optimized G-code
* Easier workflow for revising projects with new G-code
* Project/machine search
* Start simulations from the Prepare tab
* Analyze tab loads 50%+ faster
* Prepare and Optimize tabs load 2x+ faster
</More>

#### Hylo
* Improved time estimates
* More reliable filament loading and unloading
* Basis widget in Settings tab
* Chamber camera autofocus and improved picture quality
* Improved behavior when door is open
* Easier WiFi setup
* Improved screen responsiveness
* More reliable pause/resume
* French translation
<More>
* More reliable support VPN
* Easier-to-use home screen
* Graphical display of filament spool status and loading
</More>
</Box>

<Box>
### Explore
* [Build Surface Selection Guide](https://basis.aon3d.com/docs/hylo/printing/filaments)
* [Discover AM Tooling Applications](https://www.aon3d.com/applications/custom-tooling/)
* [AON3D DFAM Guide](https://www.aon3d.com/hardware/dfam-guide/)
</Box>
`;

const MarketingLink = ({
  href,
  children,
}: {
  href: string;
  children: ReactNode;
}) => {
  const urlTyped = new URL(href);
  const isInternal = urlTyped.hostname.endsWith("aon3d.com");
  const rel = isInternal ? "" : "noopener noreferrer";
  const linkProps = { href, target: "_blank", rel };
  return <S.MarketingLink {...linkProps}>{children}</S.MarketingLink>;
};

/**
 * Component overrides used in the MDX content.
 *
 * These override the default tags used by the MDX converter and will allow
 * custom React components like `<Box />`.
 */
const MDX_COMPONENTS = {
  a: MarketingLink,
  img: DocsImg,
  Box: S.WhatsNewBox,
  More: ExpandableLink,
};

/**
 * This hook simulates a react-query result so that when we later integrate
 * react-query, we can swap this out without changing usage elsewhere.
 */
const useContent = (_slug: string) => {
  const result = {
    isLoading: false,
    data: {
      content: markdownContent,
    },
  };

  return result;
};

function ExpandableLink({ children }: { children: React.ReactNode }) {
  const [expanded, setExpanded] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  const expandAnimation = useSpring({
    height: expanded ? contentRef.current?.scrollHeight : 0,
    opacity: expanded ? 1 : 0.5,
    config: { tension: 250, friction: 30 },
  });

  return (
    <div>
      <S.SeeMore onClick={() => setExpanded(!expanded)}>
        {expanded ? t`common.see-less` : t`common.see-more`}{" "}
        <S.SeeMoreChevron expanded={expanded} />
      </S.SeeMore>
      <animated.div style={{ overflow: "hidden", ...expandAnimation }}>
        <div ref={contentRef}>{children}</div>
      </animated.div>
    </div>
  );
}

export const HomeWhatsNew = (): ReactElement => {
  const pageQuery = useContent(HOMEPAGE_CONTENT_SLUG);

  if (pageQuery.isLoading) {
    return (
      <LoadingWrapper>
        <LoadingSpinner />
      </LoadingWrapper>
    );
  }

  if (!pageQuery.data) {
    console.error(`Missing home page content.`);
    return <></>;
  }

  const { content } = pageQuery.data;

  return <MdxContent content={content} />;
};

const MdxContent = ({ content }: { content: string }) => {
  // Content component must be memoized to avoid re-rendering the document tree.
  // This is necessary because the `evaluateSync` function is called on every
  // render regardless of changes to `props.content`.
  const { default: Content } = useMemo(() => {
    const options = {
      ...provider,
      ...runtime,
      useMDXComponents: () => MDX_COMPONENTS,
    } as EvaluateOptions;

    return evaluateSync(content, options);
  }, [content]);

  return <Content />;
};
