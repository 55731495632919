import { useCheckCurrentOrg, useSetCurrentOrgInternal } from "_/data/orgs";
import { useLocation } from "_/components/router";
import { Uuid } from "_/types";
import { useCallback } from "react";

export interface SetLocationAndOrgParams {
  /**
   * URL to redirect to. If the current org is not the same as `orgId`, the new
   * org will be set before changing the location.
   * */
  url?: string;

  /**
   * ID of org to switch to. If the current org is already this one, then no org
   * switch will happen.
   */
  orgId: Uuid;

  /**
   * Callback to run if an error occurs when setting the new org. If called, the
   * org was not changed. The parameters are the the HTTP status code and
   * message from the API response.
   */
  onError?: (err: { statusCode?: number; message?: string }) => void;
}

/**
 * Set the current org and location.
 *
 * If the current org is already the same as the new org, then only the location
 * will be changed.
 *
 * @returns A function that sets the current org and location.
 */
export const useSetLocationAndOrg = () => {
  const [_, setLocation] = useLocation();
  const setCurrentOrg = useSetCurrentOrgInternal();
  const { data: currentOrg } = useCheckCurrentOrg();

  return useCallback(
    (params: SetLocationAndOrgParams) => {
      const url = params.url ?? "/";
      if (currentOrg?.id === params.orgId) {
        setLocation(url);
      } else {
        // If an org change is needed, first set the new org, then navigate.
        setCurrentOrg.mutate({
          id: params.orgId,
          onSuccess: () => {
            setLocation(url);
          },
          onError: params.onError,
        });
      }
    },
    [currentOrg?.id, setCurrentOrg, setLocation]
  );
};
