import { useLayoutEffect, RefObject, useEffect, useState } from "react";

/**
 * Hook to ensure that a container's height is aligned to the height of its children,
 * so that a partial child item is not shown unless the container is scrolled.
 */
export function useEvenHeight<T>(
  containerRef: RefObject<HTMLElement>,
  dependency: T
) {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Function to handle the window resize event
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    // Add the event listener for the window resize event
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, [containerRef]);

  useLayoutEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const container = containerRef.current;
    const children = container.children;

    // Restrict to properties with string names and string values.
    type StringPropertyNames<T> = {
      [K in keyof T]: T[K] extends string ? K : never;
    }[keyof T];

    function getNumericStyles<
      T extends StringPropertyNames<CSSStyleDeclaration>,
    >(container: HTMLElement, styles: T[]) {
      const computedStyle = window.getComputedStyle(container);
      const result = {} as Partial<Record<T, number>>;

      for (const style of styles) {
        result[style] = parseFloat(computedStyle[style]) ?? 0;
      }

      return result as Record<T, number>;
    }

    const { paddingTop, paddingBottom, borderTop, borderBottom, maxHeight } =
      getNumericStyles(container, [
        "paddingTop",
        "paddingBottom",
        "borderTop",
        "borderBottom",
        "maxHeight",
      ]);

    const verticalPaddingAndBorder =
      paddingTop + paddingBottom + borderTop + borderBottom;

    let currentHeight = verticalPaddingAndBorder;

    for (let i = 0; i < children.length; i++) {
      const child = children[i] as HTMLElement;
      const childHeight = child?.getBoundingClientRect().height ?? 0;

      if (currentHeight + childHeight > maxHeight) {
        break;
      }

      currentHeight += childHeight;
    }

    container.style.height = `${currentHeight}px`;
  }, [containerRef, windowSize, dependency]);
}
