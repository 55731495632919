import React, {
  useState,
  useMemo,
  useEffect,
  ReactElement,
  Fragment,
  ClipboardEvent,
  KeyboardEvent,
  ChangeEvent,
} from "react";

import * as S from "./styled";

/**
 * The charset of allowable characters used by the auth backend.
 */
const CHARSET = "BCDFGHJKLMNPQRSTVWXYZ";

/**
 * Input character regex to only accept the valid charset.
 * This assumes that the value has been sanitized first, removing ' ' and ' '.
 */
const INPUT_REGEX = new RegExp(`^[${CHARSET}]?$`, "i");

/**
 * Regex used to strip any character not in the charset.
 * This is distinct from the `INPUT_REGEX` as it is used to clean pasted values.
 * and not to validate individual characters.
 */
const PASTE_REGEX = new RegExp(`[^${CHARSET}]`, "ig");

function cleanCode(code: string): string[] {
  const chars = code.toUpperCase().replaceAll(PASTE_REGEX, "").split("");

  return [...chars, ...Array(8).fill("")].slice(0, 8);
}

/**
 * User code input component, allowing for inputting an 8 character code.
 *
 * The input is split into 8 boxes, each accepting a single character from the
 * charset. The boxes are automatically focused as the user types, and the
 * user can use the backspace key to navigate back to previous boxes.
 *
 * The user can also paste a code into the first box, which will be split into
 * the individual characters.
 */
export const UserCodeInput = ({
  defaultValue = "",
  onSubmit,
  onChange,
}: {
  defaultValue?: string;
  onSubmit?: (code: string) => void;
  onChange: (code: string) => void;
}): ReactElement => {
  const initialValue = useMemo(() => cleanCode(defaultValue), [defaultValue]);
  const [values, setValues] = useState(initialValue);

  // If the initial value satisfies the character requirements, we can
  // call the `onChange` callback immediately to update the parent.
  useEffect(() => {
    // Ensure that the array is filled with non-blank characters. (We currently
    // pad the array with blank strings which are falsy. Using Boolean here lets
    // us change later to use `null` or `undefined`.)
    if (initialValue.every(Boolean)) {
      onChange(initialValue.join(""));
    }
  }, [onChange, initialValue]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, i: number) => {
    const value = e.target.value;

    // Do nothing if the input is invalid.
    if (!INPUT_REGEX.test(value)) {
      return;
    }

    const newValues = [...values];
    newValues[i] = value.toUpperCase();
    setValues(newValues);
    onChange(newValues.join(""));

    if (value && i < values.length - 1) {
      let next = e.target.nextElementSibling as HTMLElement;

      // Skip over dash separator.
      if (next.tagName !== "INPUT") {
        next = next.nextElementSibling as HTMLElement;
      }

      next.focus();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, i: number) => {
    if (e.key === "Backspace" && i > 0 && !values[i]) {
      // Required to appease typescript.
      const target = e.target as HTMLInputElement;
      let prev = target.previousElementSibling as HTMLElement;

      // Skip over dash separator.
      if (prev.tagName !== "INPUT") {
        prev = prev.previousElementSibling as HTMLElement;
      }

      prev.focus();
    }

    // Allow for form submission if the code-input is all filled and the focus
    // is on the last input box.
    if (e.key === "Enter" && values.join("").length === 8 && i == 7) {
      onSubmit?.(values.join(""));
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const pasteChars = cleanCode(e.clipboardData.getData("text"));
    const newValues = [...values];

    pasteChars.forEach((char, i) => {
      if (i < 8) {
        newValues[i] = char;
      }
    });

    setValues(newValues);
    onChange(newValues.join(""));
    e.preventDefault();
  };

  const charInputs = values.map((char, i) => {
    return (
      <Fragment key={i}>
        {i === 4 && <S.Separator />}
        <S.CharBox
          type="text"
          maxLength={1}
          value={char}
          onChange={(e) => handleChange(e, i)}
          onFocus={(e) => e.target.select()}
          onKeyDown={(e) => handleKeyDown(e, i)}
          onPaste={i === 0 ? handlePaste : undefined}
        />
      </Fragment>
    );
  });

  return <S.UserCodeInput>{charInputs}</S.UserCodeInput>;
};
