import React, { ReactElement, Ref, forwardRef } from "react";

import { Select, SelectProps } from "_/components/select";
import { UserAvatar } from "_/components/avatar";
import { User, useUsers, useUsersByOrg } from "_/data/users";
import { useCurrentOrg } from "_/data/orgs";
import { Uuid } from "_/types";

interface OptionType {
  value: Uuid;
  label: string;
  user: User;
}

type UserSelectionProps = Omit<SelectProps<OptionType>, "options"> & {
  users: User[];
};

type OrgUserSelectionProps = UserSelectionProps & {
  orgId?: Uuid;
};

/**
 * Select component for displaying users list dropdown.
 *
 * This will by default show all users that are visible by the currently logged in user.
 */
const _UserSelect = (
  props: UserSelectionProps,
  ref: Ref<typeof Select<OptionType>> | undefined
): ReactElement => {
  const options = props.users.map((u) => ({
    value: u.id,
    label: u.name,
    user: u,
  }));

  const formatter = (opt: (typeof options)[number]) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <UserAvatar resource={opt.user} size="small" />
        <span style={{ marginLeft: "10px" }}>{opt.user.name}</span>
      </div>
    );
  };

  return (
    <Select
      {...props}
      ref={ref}
      options={options}
      formatOptionLabel={formatter}
    />
  );
};

export const UserSelect = forwardRef(_UserSelect);

const _AllUserSelect = (
  props: OrgUserSelectionProps,
  ref: Ref<typeof Select<OptionType>> | undefined
): ReactElement => {
  const { data: users = [] } = useUsers();

  return <UserSelect {...props} ref={ref} users={users} />;
};

const _OrgUserSelect = (
  { orgId, ...props }: OrgUserSelectionProps,
  ref: Ref<typeof Select<OptionType>> | undefined
): ReactElement => {
  const { data: currentOrg } = useCurrentOrg();
  const { data: orgUsers = [] } = useUsersByOrg(orgId ? orgId : currentOrg.id);

  const users = orgUsers.map((ou) => ou.user);

  return <UserSelect {...props} ref={ref} users={users} />;
};

export const AllUserSelect = forwardRef(_AllUserSelect);
export const OrgUserSelect = forwardRef(_OrgUserSelect);
