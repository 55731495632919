import React, { useState, ReactNode, ReactElement, useEffect } from "react";
import { useSetAtom } from "jotai";
import { useQueryClient } from "react-query";
import { useLocation } from "_/components/router";

import { useSetLocationAndOrg } from "_/hooks/useSetLocationAndOrg";

import { nextUrlAtom, useTargetOrg } from "_/state";
import { api, isValidUuid } from "_/utils";
import { isPublicUrl, routeUrls } from "_/routes";

// Return the value of the `?targetOrg` UUID query parameter if it
// exists and is a valid UUID
// This is used to handle org switching based on the url, which is used
// in email notifications for new organization membership.
const getOrgQueryParam = (): string | null => {
  const queryParams = new URLSearchParams(window.location.search);
  const orgParam = queryParams.get("targetOrg");

  return isValidUuid(orgParam) ? orgParam : null;
};

type RequestInterceptorProps = {
  children: ReactNode;
};

// Intercepts HTTP requests and handles specific success and error cases
//
// If the user needs to log in, they will be redirected to a login page, then back to the original
// linked location once logged in.
//
// If a `?targetOrg` query parameter is provided, the current org will be set to this value and they will
// be redirected to the home page.
export const RequestInterceptor = ({
  children,
}: RequestInterceptorProps): ReactElement => {
  const [_, setLocation] = useLocation();
  const [ready, setReady] = useState(false);
  const setNextUrl = useSetAtom(nextUrlAtom);
  const [_targetOrg, setTargetOrg] = useTargetOrg();
  const queryClient = useQueryClient();
  const setLocationAndOrg = useSetLocationAndOrg();

  useEffect(() => {
    if (ready) {
      return;
    }

    // Check for search param in url to set current org
    const responseInterceptor: Parameters<
      typeof api.interceptors.response.use
    >[0] = (response) => {
      const orgId = getOrgQueryParam();

      if (orgId) {
        setLocationAndOrg({ orgId, url: routeUrls.index });
      }

      return response;
    };

    // Clear user and cached data on 401's - this means the user session
    // has ended.
    const errInterceptor: Parameters<
      typeof api.interceptors.response.use
    >[1] = (error) => {
      const unauthenticated = error.response?.status === 401;
      const onPublicPage = isPublicUrl(window.location.pathname);

      // If no user and accessing a private page.
      if (unauthenticated && !onPublicPage) {
        console.warn("Unauthenticated user. Clearing query cache");

        // Search params and hashes are not included in the location returned
        // by the wouter `useLocation` hook, so we construct it manually here.
        const { pathname, search, hash } = window.location;
        const nextUrl = `${pathname}${search}${hash}`;

        queryClient.clear();
        setNextUrl(nextUrl);
        setTargetOrg(getOrgQueryParam());

        setLocation(routeUrls.public.login);
      }

      return Promise.reject(error);
    };

    api.interceptors.response.use(responseInterceptor, errInterceptor);

    setReady(true);
  }, [
    ready,
    setReady,
    setNextUrl,
    setLocation,
    queryClient,
    setLocationAndOrg,
    setTargetOrg,
  ]);

  return ready ? <>{children}</> : <></>;
};
