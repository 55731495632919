import React, { ReactNode, ReactElement } from "react";
import styled from "styled-components";

import { Tooltip, TooltipProps } from "_/components/tooltip";

import * as S from "./styled";

type HelpProps = Pick<TooltipProps, "content" | "placement">;

export type FormFieldProps = {
  label?: string;
  hint?: string;
  help?: HelpProps;
  invalid?: boolean;
  className?: string;
};

const _FormField = ({
  children,
  label,
  help,
  hint,
  invalid,
  className,
}: {
  children: ReactNode;
} & FormFieldProps) => {
  return (
    <S.FormField className={className}>
      {label || help ? (
        <S.HeaderContainer>
          {label ? <S.LabelContainer>{label}</S.LabelContainer> : null}
          {help ? <Help {...help} /> : null}
        </S.HeaderContainer>
      ) : null}
      {children}
      {hint ? <S.Hint $invalid={invalid}>{hint}</S.Hint> : null}
    </S.FormField>
  );
};

export const FormField = styled(_FormField)``;

/**
 * Show info icon above form field.
 * Hovering over it reveals a tooltip describing the control.
 */
export const Help = ({ content, placement }: HelpProps): ReactElement => {
  const infoIcon = <S.InfoIcon variant="Info" />;
  return (
    <Tooltip
      target={infoIcon}
      content={content}
      placement={placement}
      delay={0}
      wait={0}
    />
  );
};
