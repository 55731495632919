import React, { useState, ReactElement, useEffect } from "react";
import { t } from "@lingui/macro";
import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";

import { TextField } from "_/components/text-field";
import { Select, StylesConfig } from "_/components/select";
import { Table } from "_/components/table";
import { Button } from "_/components/button";
import { imageURL, thumbURL } from "_/data/images";
import {
  TelemetryEventKind,
  EventQuery,
  ImageEventData,
  LogEventData,
  Machine,
  StateChangeEventData,
  TelemetryEvent,
  ThermalsEventData,
  useMachineEvents,
} from "_/data/machines";
import { Image } from "_/components/image";
import { Badge } from "_/components/badge";
import { DateTime } from "_/components/date-time";

import * as S from "./styled";
import { formatTemperature } from "_/utils";

type MachineEventsProps = {
  machine: Machine;
};

// Constructs translated label for event kinds.
function translatedLabel(kind: string): string {
  return t`common.event-kinds ${kind}`;
}

// Helper for rendering the event "kind" in each table row.
const RenderKind = ({ event }: { event: TelemetryEvent }) => {
  const kind = event.kind;
  const label = translatedLabel(kind);

  switch (kind) {
    case "thermals":
      return <Badge kind="warning">{label}</Badge>;
    case "stateChange":
      return <Badge kind="info">{label}</Badge>;
    case "log":
      return <Badge kind="highlight">{event.data.level}</Badge>;
    case "systemProfile":
      return <Badge kind="highlight">{label}</Badge>;
    case "startup":
      return <Badge kind="success">{label}</Badge>;
      break;
    case "image":
      return <Badge kind="highlight">{label}</Badge>;
    default:
      return <Badge kind="muted">Unknown ({kind})</Badge>;
  }
};

// Helper for rendering event data in each table row.
const RenderData = ({ event }: { event: TelemetryEvent }) => {
  function renderThermals(data: ThermalsEventData) {
    const temps = Object.keys(data).map((k, i) => {
      const val = data[k];
      return (
        <S.ThermalsReading key={i}>
          <S.SensorLabel>{k}:</S.SensorLabel>
          <S.SensorTemp>
            {formatTemperature({ value: val.temperature, kind: "current" })}
          </S.SensorTemp>
          <S.SensorTarget>
            /{formatTemperature({ value: val.target, kind: "target" })}
          </S.SensorTarget>
        </S.ThermalsReading>
      );
    });

    return <S.ThermalDataWrapper>{temps}</S.ThermalDataWrapper>;
  }

  function renderStateChange(data: StateChangeEventData) {
    return (
      <S.StateChange>
        <S.RawJson>{JSON.stringify(data, null, 2)}</S.RawJson>
      </S.StateChange>
    );
  }

  function renderLogMessage(data: LogEventData) {
    return <S.LogMessage>{data.message}</S.LogMessage>;
  }

  function renderImage(data: ImageEventData) {
    const src = imageURL(data.imageId);
    const thumb = thumbURL(data.imageId);
    return <Image src={src} thumb={thumb} />;
  }

  // TODO: Add prettier output for startup data and profiles.
  function renderDefault(data: unknown) {
    return <S.RawJson>{JSON.stringify(data, null, 2)}</S.RawJson>;
  }

  switch (event.kind) {
    case "thermals":
      return renderThermals(event.data);
    case "stateChange":
      return renderStateChange(event.data);
    case "startup":
      return renderDefault(event.data);
    case "log":
      return renderLogMessage(event.data);
    case "image":
      return renderImage(event.data);
    default:
      return renderDefault(event.data);
  }
};

const RenderTimestamp = ({ event }: { event: TelemetryEvent }) => {
  const ts = new Date(event.timestamp / 1_000);

  return (
    <span title={ts.toISOString()}>
      <DateTime value={ts} precision="millisecond" />
    </span>
  );
};

type QueryParams = Omit<EventQuery, "machineId">;

const FilterBar = ({
  queryParams,
  onChange,
}: {
  queryParams: QueryParams;
  onChange: (params: QueryParams) => void;
}) => {
  const [params, setParams] = useState<QueryParams>(queryParams);

  function update<K extends keyof QueryParams>(k: K) {
    return (value: QueryParams[K]) => setParams((p) => ({ ...p, [k]: value }));
  }

  // Change handler for the select input.
  function selectChange(vs: Array<TelemetryEventKind | null>) {
    const kind = vs.indexOf(null) > 0 ? [] : vs.filter((v) => v != null);
    update("kind")(kind);
    onChange({ ...params, kind });
  }

  const kindOptions = [
    { value: null, label: t`common.all` },
    { value: "thermals", label: translatedLabel("thermals") },
    { value: "stateChange", label: translatedLabel("stateChange") },
    { value: "startup", label: translatedLabel("startup") },
    { value: "systemProfile", label: translatedLabel("systemProfile") },
    { value: "log", label: translatedLabel("log") },
    { value: "image", label: translatedLabel("image") },
  ];

  const styles: StylesConfig<(typeof kindOptions)[number]> = {
    multiValueLabel: (base, state) => {
      return state.data.value === null ? { ...base, padding: "3px 6px" } : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.value === null ? { ...base, display: "none" } : base;
    },
  };

  return (
    <S.FilterBar>
      <S.FilterBarItem>
        <S.Label>{t`components.machine-events.kind`}:</S.Label>
        <Select
          options={kindOptions}
          value={params.kind?.length ? params.kind : [null]}
          isClearable={false}
          styles={styles}
          onChange={selectChange}
          multiple
        />
      </S.FilterBarItem>
      <S.FilterBarItem>
        <S.Label>{t`components.machine-events.limit`}:</S.Label>
        <TextField
          type="number"
          value={params.limit}
          min={1}
          max={1000}
          onChange={update("limit")}
        />
      </S.FilterBarItem>
      <Button size="small" onClick={() => onChange(params)}>
        {t`components.machine-events.run-query`}
      </Button>
    </S.FilterBar>
  );
};

export const MachineEvents = ({
  machine,
}: MachineEventsProps): ReactElement => {
  const { lastSeen } = machine;

  // Show events from a week before we last saw the machine
  // Note that these number values are in microseconds.
  const from = lastSeen
    ? dayjs(lastSeen).subtract(7, "days").valueOf() * 1000
    : undefined;
  const to = lastSeen
    ? dayjs(lastSeen).add(1, "minutes").valueOf() * 1000
    : undefined;

  const [queryParams, setQueryParams] = useState<QueryParams>({
    from,
    to,
    limit: 50,
    order: "desc",

    // Default to showing only stateChange and startup events. Image and
    // thermals events are very frequent and are usually not interesting to the
    // user. Snapshot events are interesting, but they are already displayed
    // elsewhere in machine detail. Log and systemProfile events are, AFAICT,
    // not used.
    kind: ["stateChange", "startup"] as TelemetryEventKind[],
  });

  useEffect(() => {
    setQueryParams((p) => ({ ...p, from, to }));
  }, [from, to]);

  const { data: events = [] } = useMachineEvents({
    machineId: machine.id,
    ...queryParams,
  });

  const tableColumns: ColumnDef<TelemetryEvent>[] = [
    {
      header: t`common.event-time`,
      accessorKey: "timestamp",
      cell: ({ row: { original: rowData } }) => (
        <RenderTimestamp event={rowData} />
      ),
    },
    {
      header: t`common.type`,
      accessorKey: "kind",
      cell: ({ row: { original: rowData } }) => <RenderKind event={rowData} />,
    },
    {
      header: t`common.data`,
      accessorKey: "data",
      cell: ({ row: { original: rowData } }) => <RenderData event={rowData} />,
    },
  ];

  return (
    <S.Wrapper>
      <FilterBar queryParams={queryParams} onChange={setQueryParams} />
      <S.Content>
        <Table columns={tableColumns} data={events} />
      </S.Content>
    </S.Wrapper>
  );
};
