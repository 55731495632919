import { useMemo } from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { AxiosError } from "axios";

import { api, ApiResponse } from "_/utils";
import { Uuid } from "_/types";

import { PERMISSIONS_KEYS, permissions, Permission } from "_/data/rbac";

const SCOPE = "orgs";
const KEYS = {
  all: () => [SCOPE],
  org: (id: Uuid) => [SCOPE, "detail", id],
  current: () => [SCOPE, "current"],
};

export type Org = {
  /**
   * Organization ID.
   */
  id: Uuid;

  /**
   * Organizational domain ID.
   */
  domainId: Uuid;

  /**
   * Organization canonical name.
   */
  name: string;

  /**
   * Organization avatar blob ID.
   */
  avatarId?: string;
};

export type OrganizationWithRbac = Org & {
  roles: string[];
  permissions: Permission[];
  isMember: boolean;
};

export type OrgUpdate = Pick<Org, "name">;

/**
 * Get the details of a specific organization by ID.
 */
async function getOrg(params: { id: Uuid }): Promise<OrganizationWithRbac> {
  const response = await api.get<ApiResponse<OrganizationWithRbac>>(
    `/organizations/${params.id}`
  );
  return response.data.data;
}

/**
 * Query hook for [[`getOrg`]].
 */
export function useOrg(id: Uuid | undefined) {
  const config: UseQueryOptions<OrganizationWithRbac> = {
    queryKey: id ? KEYS.org(id) : undefined,
    queryFn: id ? () => getOrg({ id }) : undefined,
  };

  return useQuery(config);
}

/**
 * Get listing of all orgs available to the current user.
 */
export async function getOrgs(): Promise<OrganizationWithRbac[]> {
  const response =
    await api.get<ApiResponse<OrganizationWithRbac[]>>(`/organizations`);
  return response.data.data;
}

/**
 * Query hook for [[`getOrgs`]].
 *
 * This hook is for the initial retrieval of orgs, allowing for the `useOrgs`
 * hook to be used for subsequent queries with a guaranteed defined value.
 */
export function useCheckOrgs() {
  const queryClient = useQueryClient();

  const config: UseQueryOptions<OrganizationWithRbac[]> = {
    queryKey: KEYS.all(),
    queryFn: getOrgs,
    onSuccess: (data) => {
      data.map((orgWithRbac) => {
        const orgKey = KEYS.org(orgWithRbac.id);

        queryClient.setQueryData(orgKey, orgWithRbac);

        const permissions = orgWithRbac.permissions;

        queryClient.setQueryData(
          PERMISSIONS_KEYS.currentUser(orgWithRbac.domainId),
          permissions
        );
      });
    },
  };

  return useQuery(config);
}

/**
 * Query hook for [[`getOrgs`]].
 */
export function useOrgs(filter?: (org: OrganizationWithRbac) => boolean) {
  const config: UseQueryOptions<OrganizationWithRbac[]> = {
    queryKey: KEYS.all(),
    queryFn: getOrgs,
    select: filter ? (orgs) => orgs.filter(filter) : undefined,
  };
  const response = useQuery(config);

  if (!response.data) {
    throw new Error("Orgs data is required but was not provided.");
  }

  return response;
}

export const useAon3dOrgs = () => {
  // Filtering orgs by name is a temporary solution until we can have a more
  // durable flag to mark internal orgs.
  const filter = (org: OrganizationWithRbac) => org.name.startsWith("AON3D");
  const result = useOrgs(filter);

  const orgs = result?.data;
  if (orgs?.length === 0) {
    throw new Error("No AON3D orgs, which should not happen in production.");
  }
  return orgs;
};

export const useFactoryOrg = () => {
  // Finding the factory org by name isn't the best way to do this long-term.
  // Long-term we should probably have a flag or org permission or something
  // that indicates that an org is a factory org.
  const filter = (org: OrganizationWithRbac) =>
    org.name.startsWith("AON3D Manufacturing") ||
    org.name.startsWith("AON3D Factory");
  const orgs = useAon3dOrgs().filter(filter);

  // Defend against other orgs using the same name.
  if (orgs.length > 1) {
    throw new Error("Multiple factory orgs found. Rename the wrong one(s).");
  }

  const factoryOrg = orgs[0];
  if (!factoryOrg) {
    throw new Error("Factory org not found.");
  }
  return factoryOrg;
};

/**
 * Hook to retrieve the list of organizations the current user is a member of.
 */
export function useMemberOrgs() {
  const config: UseQueryOptions<OrganizationWithRbac[]> = {
    queryKey: KEYS.all(),
    queryFn: getOrgs,
    select: (orgs) => orgs.filter((org) => org.isMember),
  };

  const response = useQuery(config);

  if (!response.data) {
    throw new Error("Orgs data is required but was not provided.");
  }

  return response;
}

/**
 * Hook to check if the user should have access to admin tools for any of the
 * orgs visible to them.
 */
export function useHasAdminAccess() {
  const { data: orgs } = useOrgs();

  const hasAdminOrgs = useMemo(() => {
    return orgs.some((org) => {
      return org.permissions.includes(permissions.views.manage);
    });
  }, [orgs]);

  return hasAdminOrgs;
}

/**
 * Retrieve the currently active organization for the user session.
 */
async function currentOrg(): Promise<OrganizationWithRbac | null> {
  const response = await api.get<ApiResponse<OrganizationWithRbac | null>>(
    "/organizations/current"
  );
  return response.data.data;
}

/**
 * Query hook for [[`currentOrg`]].
 *
 * This hook is for the initial retrieval of the current org, allowing
 * for the `useCurrentOrg` hook to be used for subsequent queries with
 * a guaranteed defined value.
 */
export function useCheckCurrentOrg() {
  const config: UseQueryOptions<Org | null> = {
    placeholderData: null,
    queryKey: KEYS.current(),
    queryFn: currentOrg,
  };

  return useQuery(config);
}

/**
 * Query hook for [[`currentOrg`]].
 */
export function useCurrentOrg() {
  const config: UseQueryOptions<OrganizationWithRbac | null> = {
    queryKey: KEYS.current(),
    queryFn: currentOrg,
  };

  const response = useQuery(config);

  const data = response.data;

  if (!data) {
    throw new Error("Current org data is required but was not provided.");
  }

  return { ...response, data };
}

/**
 * Mutation to set the currently active org for the user session.
 *
 * Setting the current org is always followed by a redirect, because the current
 * URL might be invalid in the new org, so we must navigate to a known-valid
 * page in that org.
 *
 * Therefore, use of this hook directly is discouraged. Use
 * `useSetLocationAndOrg` instead.
 */
export function useSetCurrentOrgInternal() {
  const queryClient = useQueryClient();

  async function setCurrentOrg({
    id,
  }: {
    id: Uuid;
    onError?: (err: { statusCode?: number; message?: string }) => void;
    onSuccess?: (org: Org) => void;
  }): Promise<Org> {
    // The organization must be one of the organizations the current user is a
    // member of, otherwise a 403 response will be returned.
    const response = await api.post<ApiResponse<Org>>(
      "/organizations/current",
      {
        id,
      }
    );
    return response.data.data;
  }

  return useMutation({
    mutationFn: setCurrentOrg,
    onSuccess: (org: Org, { onSuccess }) => {
      // When switching orgs, invalidate all data from the old org
      queryClient.invalidateQueries();
      queryClient.setQueryData(KEYS.current(), org);
      queryClient.setQueryData(KEYS.org(org.id), org);

      onSuccess?.(org);
    },
    onError: (e: AxiosError, { onError }) => {
      const responseData = e.response?.data as { message?: string } | undefined;
      onError?.({
        statusCode: e.response?.status,
        message: responseData?.message,
      });
    },
  });
}

/**
 * Create a new org.
 */
async function createOrg(params: {
  name: string;
  makeCurrentUserMember: boolean;
}): Promise<Org> {
  const response = await api.post<ApiResponse<Org>>(`/organizations`, params);
  return response.data.data;
}

/**
 * Mutation for [['createOrg']].
 */
export function useCreateOrg() {
  const queryClient = useQueryClient();

  const config = {
    mutationFn: createOrg,
    onSuccess: () => {
      queryClient.invalidateQueries(KEYS.all());
    },
  };

  return useMutation(config);
}

/**
 * Update information for the specified org.
 */
async function updateOrg(params: {
  id: Uuid;
  update: OrgUpdate;
}): Promise<Org> {
  const response = await api.patch<ApiResponse<Org>>(
    `/organizations/${params.id}`,
    params.update
  );
  return response.data.data;
}

/**
 * Mutation for [['updateOrg']].
 */
export function useUpdateOrg() {
  const queryClient = useQueryClient();

  const config = {
    mutationFn: updateOrg,
    onSuccess: (updatedOrg: Org) => {
      queryClient.setQueryData(KEYS.org(updatedOrg.id), updatedOrg);

      queryClient.setQueryData<Org[]>(KEYS.all(), (oldOrgs) =>
        oldOrgs
          ? oldOrgs.map((org) => (org.id === updatedOrg.id ? updatedOrg : org))
          : [updatedOrg]
      );
    },
  };

  return useMutation(config);
}
