import styled from "styled-components";
import { readableColor } from "polished";

import { Icon } from "_/components/icon";

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const DocsNav = styled.nav`
  width: 300px;
  padding: 20px 20px 20px 40px;
`;

export const DocsNavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const DocsNavItem = styled.li<{ $depth: number; $active: boolean }>`
  position: relative;
  padding: 1px 0;
  margin-left: ${(p) => p.$depth}em;
  font-size: 14px;
  color: ${(p) => (p.$active ? p.theme.text.default : p.theme.text.secondary)};
  display: flex;
  align-items: center;
`;

export const DocsNavItemArrow = styled.div<{ $expanded: boolean }>`
  display: inline-block;
  position: absolute;
  cursor: pointer;

  width: 0;
  height: 0;

  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid
    ${(p) => (p.$expanded ? p.theme.text.default : p.theme.text.secondary)};

  left: -1em;

  transition: transform 0.15s ease-in-out;
  transform: rotate(${(p) => (p.$expanded ? "0deg" : "-90deg")});
`;

export const MainContent = styled.div`
  padding: 20px 60px 200px;
  max-height: 100%;
  max-width: 1200px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  color: ${(p) => p.theme.text.default};
  background-color: ${(p) => p.theme.background.primary};
  width: 100%;

  @media screen and (max-width: 1200px) {
    padding: 20px 40px 160px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;

    a {
      color: inherit;
    }

    &:not(:first-child) {
      margin-top: 2.5em;
    }

    &:target {
      position: relative;

      &::before {
        content: "⏵";
        display: block;
        position: absolute;
        left: -1em;
      }
    }
  }

  li::marker {
    font-family: monospace;
    margin-right: 0.25em;
  }

  code {
    margin: 0;
    color: ${(p) => p.theme.text.secondary};
    font-family: monospace;
    font-size: 95%;
    background-color: ${(p) => p.theme.background.secondary};
    padding: 0.1em 0.4em;
    border-radius: 2px;
    position: relative;
    top: -0.1em;
  }

  pre {
    border-radius: 6px;
    overflow: hidden;

    code {
      background-color: transparent;
      display: block;

      * {
        font-family: inherit;
      }
    }
  }

  a {
    color: ${(p) => p.theme.color.brand.blue};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const CopyCode = styled.span`
  display: block;
  position: absolute;
  right: 1em;
  top: 1em;
  padding: 8px;
  border-radius: 4px;

  background-color: ${(p) => p.theme.background.secondary};

  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.background.tertiary};
  }

  &:active {
    background-color: ${(p) => p.theme.background.quaternary};
  }
`;

export const MdxContentWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const ModifiedAt = styled.div`
  margin-top: 2em;
  color: ${(p) => p.theme.text.muted};
  font-size: 0.8em;
`;

export const TocContent = styled.div`
  padding: 20px;
  color: ${(p) => p.theme.text.default};
  background-color: ${(p) => p.theme.background.primary};
  min-width: 260px;

  h3 {
    margin: 0;
  }

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

export const PageToc = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const PageTocItem = styled.li<{ $depth: number }>`
  padding: 1px 0;
  margin-left: ${(p) => p.$depth}em;
  font-size: 14px;
  color: ${(p) => p.theme.text.secondary};

  &:hover {
    span {
      color: ${(p) => p.theme.text.hover};
    }
  }
`;

export const DocsHr = styled.hr`
  border: 1px solid ${(p) => p.theme.border};
  border-radius: 2px;
`;

export const DocsImg = styled.img`
  max-width: 100%;
`;

export const Notice = styled.div<{ $bgColor: string }>`
  padding: 1.5em 2em;
  margin: 1.6em 0;
  border-radius: 6px;
  background-color: ${(p) => p.$bgColor};
  color: ${(p) =>
    readableColor(
      p.$bgColor,
      p.theme.color.palette.almostBlack,
      p.theme.color.palette.snowWhite
    )};

  a {
    color: ${(p) =>
      readableColor(
        p.$bgColor,
        p.theme.color.palette.electricBlue,
        p.theme.color.palette.twilightBlue
      )};
  }
`;

export const NoticeHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;

  ${Icon} {
    font-size: 1.5em;
  }
`;

export const NoticeTitle = styled.span`
  margin: 0 0 0 0.5em;
  font-size: 1.25em;
`;

export const LoadingWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingSpinner = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 2px solid ${(p) => p.theme.color.brand.blue};

  animation: spin ease-in 0.8s alternate infinite;

  @keyframes spin {
    0% {
    }

    100% {
      width: 4px;
      height: 4px;
    }
  }
`;

export const ContentsListing = styled.div`
  padding: 1.2em 2em;
  margin: 3em 0;
  border-radius: 6px;
  background-color: ${(p) => p.theme.background.secondary};
`;

export const EditLink = styled.a`
  color: ${(p) => p.theme.link.default};
  display: flex;
  align-items: center;
  gap: 0.2em;
  font-size: 0.8em;
`;
