import React, { ReactElement, HTMLAttributes } from "react";
import { t } from "@lingui/macro";

import { type IconVariant } from "_/components/icon";
import { type AttemptStatus, type JobStatus } from "_/data/projects";
import { type MachineStatus } from "_/data/machines";

import * as S from "./styled";

export type BadgeKind =
  | "muted"
  | "info"
  | "active"
  | "error"
  | "warning"
  | "success"
  | "highlight";

type BadgeProps = HTMLAttributes<HTMLDivElement> & {
  kind?: BadgeKind;
  /**
   * Name of icon variant to display to the left of the label;
   * by default, the "Circle" icon variant will be displayed.
   */
  icon?: IconVariant;
};

export const Badge = ({
  kind = "muted",
  icon = "Circle",
  children,
  ...props
}: BadgeProps): ReactElement => {
  return (
    <S.Wrapper {...props} $kind={kind}>
      {icon ? <S.Icon variant={icon} $kind={kind} /> : null}
      {children}
    </S.Wrapper>
  );
};

export const StatusBadge = ({
  status,
}: {
  status: MachineStatus | AttemptStatus | JobStatus | "detached";
}) => {
  const statusMap = {
    // machine states
    standby: { kind: "info", statusLabel: t`common.standby` },
    printing: { kind: "active", statusLabel: t`common.printing` },
    paused: { kind: "warning", statusLabel: t`common.paused` },
    complete: { kind: "success", statusLabel: t`common.complete` },
    cancelled: { kind: "highlight", statusLabel: t`common.cancelled` },
    error: { kind: "error", statusLabel: t`common.error` },
    unknown: { kind: "muted", statusLabel: t`common.unknown` },
    detached: { kind: "muted", statusLabel: t`common.detached` },

    // attempt states (not already captured above)
    failed: { kind: "error", statusLabel: t`common.error` },

    // simulation states (not already captured above)
    started: { kind: "active", statusLabel: t`common.simulating` },
    queued: { kind: "warning", statusLabel: t`common.queued` },
    pending: { kind: "active", statusLabel: t`common.pending` },
    completed: { kind: "success", statusLabel: t`common.complete` },
  } as const;

  const mapEntry = statusMap[status] ?? statusMap.unknown;
  const { kind, statusLabel } = mapEntry;

  return <Badge kind={kind}>{statusLabel}</Badge>;
};
