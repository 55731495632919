import React, { Children, ReactNode } from "react";
import { intersperse, uniqWith, init, pipe, unnest, pluck, uniq } from "ramda";
import { t } from "@lingui/macro";

import { Icon, IconVariant } from "_/components/icon";

import {
  DocsIndexDoc,
  MachinesIndexDoc,
  ProjectsIndexDoc,
  useDocsIndex,
  useMachinesIndex,
  useProjectsIndex,
} from "./indices";
import { Highlight } from "./highlight";
import * as S from "./styled";

/**
 * Search result type for use within this codebase.
 *
 * There seems to be a bug in the flexsearch types which does not allow for
 * correctly typing "enriched" search results, so we have to define our own
 * type here to work around dealing with an `unknown` (or incorrect) type.
 */
type SearchResult<T, R> = Array<{
  /**
   * Canonical result ID.
   *
   * This is typically a UUID, but can be any type.
   */
  id: T;

  /**
   * Search result document content which may be an entirely different type o
   * than the target entity type.
   */
  doc: R;
}>;

/**
 * Search props interface across all search components.
 */
interface SearchProps {
  /**
   * Query string needle.
   */
  query: string;

  /**
   * Search result limit.
   */
  limit: number;

  /**
   * On click handler for search result items.
   *
   * This will be called in addition to any local logic performed by the search
   * component items themselves (e.g. any required cleanup tasks).
   */
  onClick: () => void;
}

type ResultSectionProps = {
  title: string;
  icon: IconVariant;
  items: ReactNode;
};

/**
 * Component for rendering a search result section.
 *
 * This simply provides the format and structure for consistency between
 * results sections. Provided items can be in any form desired, but
 * typically should be a list of <S.ResultItem> wrapped components.
 */
const ResultSection = ({ icon, title, items }: ResultSectionProps) => {
  const noResults =
    Children.count(items) === 0 ? (
      <S.NoResults>{t`search.no-results`}</S.NoResults>
    ) : null;

  return (
    <S.SearchResultSection>
      <S.SectionHeader>
        <Icon variant={icon} />
        <S.SectionTitle>{title}</S.SectionTitle>
      </S.SectionHeader>
      <S.SectionResults>
        {items}
        {noResults}
      </S.SectionResults>
    </S.SearchResultSection>
  );
};

/**
 * Helper function for currying the <Highlight> component.
 */
function highlighter(query: string) {
  const Hl = ({ text }: { text: string }) => (
    <Highlight text={text} query={query} />
  );

  return Hl;
}

/**
 * Search across all visible machines and hardware.
 *
 * This search will consider:
 *
 * - machine name
 * - hardware serial number
 */
export const MachinesSearch = ({ query, limit, onClick }: SearchProps) => {
  const machinesIndex = useMachinesIndex();

  if (query === "") {
    return null;
  }

  const conf = { enrich: true, limit };
  const results = machinesIndex.search(query, conf);
  const clean = pipe(pluck("result"), unnest, uniq);
  const cleaned = clean(results) as SearchResult<string, MachinesIndexDoc>;

  const Hl = highlighter(query);

  const items = cleaned.map((result, i) => (
    <S.ResultItem key={i} to={`/machines/${result.id}`} onClick={onClick}>
      <S.ResultTitle>
        <Hl text={result.doc.name} />
      </S.ResultTitle>
      <S.Muted>
        <Highlight text={result.doc.hardware.serial} query={query} />
      </S.Muted>
    </S.ResultItem>
  ));

  return (
    <ResultSection title={t`common.machines`} icon={"Machines"} items={items} />
  );
};

/**
 * Documentation content search.
 *
 * See the `docs` repository for more information on how the docs
 * search index is built and which fields and content properties
 * will be considered in the search.
 */
export const DocsSearch = ({ query, limit, onClick }: SearchProps) => {
  const docsIndex = useDocsIndex();

  if (query === "") {
    return null;
  }

  const conf = { enrich: true, limit };
  const results = docsIndex.search(query, conf);
  const clean = pipe(pluck("result"), unnest, uniq);
  const cleaned = clean(results) as SearchResult<string, DocsIndexDoc>;

  const Hl = highlighter(query);

  const items = cleaned.map((result, i) => {
    // Display a breadcrumb for root documents.
    const breadcrumbs =
      result.doc.breadcrumbs.length == 1
        ? result.doc.breadcrumbs
        : init(result.doc.breadcrumbs);

    return (
      <S.ResultItem key={i} to={`/docs/${result.id}`} onClick={onClick}>
        <S.ResultTitle>
          <Hl text={result.doc.title} />
        </S.ResultTitle>
        <S.Muted>{breadcrumbs.join(" / ")}</S.Muted>
      </S.ResultItem>
    );
  });

  return <ResultSection title={t`common.docs`} icon={"Docs"} items={items} />;
};

/**
 * Project and revisions search.
 *
 * This search will consider:
 *
 * - project name
 * - project owner name
 * - project description
 * - revision materials
 * - revision operator name
 */
export const ProjectsSearch = ({ query, limit, onClick }: SearchProps) => {
  const projectsIndex = useProjectsIndex();

  if (query === "") {
    return null;
  }

  const conf = { enrich: true, limit };
  const results = projectsIndex.search(query, conf);
  const clean = pipe(pluck("result"), unnest, uniq);
  const cleaned = clean(results) as SearchResult<string, ProjectsIndexDoc>;

  const Hl = highlighter(query);

  const items = cleaned.map((result, i) => {
    // Materials deduplicated for cases where both tools use the same material.
    const materials = uniqWith(
      (a, b) => a.id === b.id,
      result.doc.materials
    ).map((m, i) => <Hl key={i} text={m.name} />);

    const revisionCount = t`search.revision-count ${result.doc.revisionCount}`;

    return (
      <S.ResultItem key={i} to={`/projects/${result.id}`} onClick={onClick}>
        <S.ResultTitle>
          <Hl text={result.doc.name} />
        </S.ResultTitle>
        <S.Muted>
          {intersperse(<S.Slash />, materials)}
          <S.Bar />
          <Hl text={result.doc.owner.name} />
          <S.Bar />
          {revisionCount}
        </S.Muted>
      </S.ResultItem>
    );
  });

  return (
    <ResultSection title={t`common.projects`} icon={"Projects"} items={items} />
  );
};
