import React, { KeyboardEvent, useRef, useState } from "react";

import { Icon } from "_/components/icon";
import { useEvenHeight } from "_/hooks";

import { ProjectsSearch, MachinesSearch, DocsSearch } from "./searches";
import * as S from "./styled";

export const SearchBox = () => {
  const [query, setQuery] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const resultsRef = useRef<HTMLDivElement>(null);

  useEvenHeight(resultsRef, query);

  const reset = () => {
    setQuery("");
    (document.activeElement as HTMLElement)?.blur();
  };

  const handleKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    switch (key) {
      case "Escape":
        (document.activeElement as HTMLElement)?.blur();
        break;
    }
  };

  return (
    <S.Wrapper>
      <S.SearchInputWrapper>
        <Icon variant="Search" />
        <S.SearchInput
          ref={inputRef}
          type="text"
          role="combobox"
          name="q"
          value={query}
          autoComplete="off"
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </S.SearchInputWrapper>
      <S.SearchResults ref={resultsRef}>
        <ProjectsSearch query={query} limit={3} onClick={reset} />
        <MachinesSearch query={query} limit={3} onClick={reset} />
        <DocsSearch query={query} limit={4} onClick={reset} />
      </S.SearchResults>
    </S.Wrapper>
  );
};
