import React, { ReactElement } from "react";

export const Search = (): ReactElement => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 23 23"
      fill="none"
      strokeWidth="0.125em"
      strokeLinecap="round"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9.5" cy="9.5" r="8.5" />
      <path d="M21.5 21.5L15.8431 15.8431" />
    </svg>
  );
};
