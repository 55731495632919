import { useMemo, useRef } from "react";
import FlexSearch from "flexsearch";

import { useMachines } from "_/data/machines";
import { useMaterials } from "_/data/materials";
import { useProjects } from "_/data/projects";
import { useUsers } from "_/data/users";
import { useDocsSearchIndex } from "_/data/docs";

import { Uuid } from "_/types";
import { indexById } from "_/utils";

export type DocsIndexDoc = {
  slug: string;
  breadcrumbs: string[];
  title: string;
};

export const useDocsIndex = () => {
  const { data: docsIndex, isFetched } = useDocsSearchIndex();
  const indexRef = useRef(
    new FlexSearch.Document<DocsIndexDoc, ["title", "breadcrumbs", "slug"]>({
      tokenize: "full",
      document: {
        id: "slug",
        index: ["title", "content"],
        store: ["title", "breadcrumbs", "slug"],
      },
    })
  );

  return useMemo(() => {
    if (!isFetched) {
      return indexRef.current;
    }

    // Cast to appease typechecker.
    const typedDocsIndex = docsIndex as Record<string, DocsIndexDoc>;

    for (const key in typedDocsIndex) {
      indexRef.current.import(key, typedDocsIndex[key]);
    }

    console.debug("Docs search index computed.");

    return indexRef.current;
  }, [docsIndex, isFetched]);
};

export type MachinesIndexDoc = {
  id: Uuid;
  name: string;
  lastSeen: string | null;
  hardwareAttached: boolean;
  hardware: {
    serial: string;
  };
};

export const useMachinesIndex = () => {
  const { data: machines } = useMachines();

  return useMemo(() => {
    const index = new FlexSearch.Document<MachinesIndexDoc, string[]>({
      tokenize: "full",
      document: {
        id: "id",
        index: ["name", "hardware:serial"],
        store: [
          "id",
          "name",
          "lastSeen",
          "hardwareAttached",
          "hardware:serial",
        ],
      },
    });

    if (machines) {
      machines.forEach((machine) => {
        index.add(machine);
      });
    }

    console.debug("Machines search index computed.");

    return index;
  }, [machines]);
};

export type ProjectsIndexDoc = {
  id: Uuid;
  name: string;
  owner: {
    id: string;
    name: string;
  };
  description: string;
  materials: Array<{
    id: string;
    name: string;
  }>;
  revisionCount: number;
};

export const useProjectsIndex = () => {
  const { data: projects = [], isFetched: projectsFetched } = useProjects();
  const { data: materials = [], isFetched: materialsFetched } = useMaterials();
  const { data: users = [], isFetched: usersFetched } = useUsers();

  // All three catalogues are required for building the projects index, so we
  // can coalesce to a single value for concision and use in the useMemo hook.
  const isFetched = projectsFetched && materialsFetched && usersFetched;

  return useMemo(() => {
    const index = new FlexSearch.Document<ProjectsIndexDoc, string[]>({
      tokenize: "full",
      document: {
        id: "id",
        index: ["name", "owner:name", "description", "materials[]:name"],
        store: ["name", "owner", "description", "materials", "revisionCount"],
      },
    });

    if (!isFetched) {
      return index;
    }

    const materialsById = indexById(materials);
    const usersById = indexById(users);

    projects.forEach((project) => {
      const materials = project.revisions
        .map((rev) => rev.materials)
        .flat()
        .filter(Boolean)
        .map((id) => {
          const mat = materialsById[id];
          return {
            id: mat.id,
            name: mat.name,
          };
        });

      const owner = {
        id: project.ownerId,
        name: usersById[project.ownerId].name,
      };

      index.add({
        id: project.id,
        name: project.name,
        description: project.description,
        owner,
        materials,
        revisionCount: project.revisions.length,
      });
    });

    console.debug("Projects search index computed.");

    return index;
  }, [projects, materials, users, isFetched]);
};
