import React, { ReactNode } from "react";
import { t } from "@lingui/macro";
import { useRoute, useLocation } from "_/components/router";
import { TabBar, Tab } from "_/components/tab-bar";
import { MachineLastSeen } from "_/components/machine-listing";
import { StatusBadge } from "_/components/badge";
import { MachineDetailsProps } from "_/components/machine-details";
import { calculateMachineLastSeenKind } from "_/components/machine-listing";

import { Machine, useMachine } from "_/data/machines";
import { machineRoutes, machineUrls } from "_/routes";

import * as S from "./styled";

export const MachineStatusBadge = ({
  machine,
  updatedAt,
}: MachineDetailsProps & {
  updatedAt: number;
}) => {
  // If there is no attached hardware, skip displaying any machine status.
  if (!machine.hardwareAttached) {
    return <StatusBadge status="detached" />;
  }

  // Regardless of the last status reported, if a machine hasn't recently sent
  // any telemetry, the status is stale so show it in an unknown status because
  // we don't really know what state it's in.
  const lastSeenKind = calculateMachineLastSeenKind({ machine, updatedAt });
  if (lastSeenKind === "offline" || lastSeenKind === "never") {
    return <StatusBadge status="unknown" />;
  }

  return <StatusBadge status={machine.status} />;
};

type MachineHeaderProps = {
  machine: Machine;
};

export const MachineHeader = ({
  machine: { id: machineId },
}: MachineHeaderProps): ReactNode => {
  const [_location] = useLocation();

  const [overviewActive, _overviewParams] = useRoute(machineRoutes.index);
  const [thermalsActive, _thermalsParams] = useRoute(machineRoutes.thermals);
  const [eventsActive, _eventsParams] = useRoute(machineRoutes.events);
  const [configActive, _configParams] = useRoute(machineRoutes.configuration);

  // Call the useMachine hook again in order to get the updatedAt timestamp
  const { data: machine, dataUpdatedAt: updatedAt } = useMachine(machineId);

  if (!machine) return null;

  return (
    <S.Wrapper>
      <S.Details>
        <S.LeftSection>
          <S.Meta>
            <S.Title>{machine.name}</S.Title>
            <S.Model>{machine.hardware.model}</S.Model>
          </S.Meta>
        </S.LeftSection>
        <S.RightSection>
          <S.RightSectionItems>
            <S.RightSectionItem>
              <S.Label>{t`common.status`}:</S.Label>
              <MachineStatusBadge machine={machine} updatedAt={updatedAt} />
            </S.RightSectionItem>
            <S.RightSectionItem>
              <S.Label>{t`common.machine-last-seen`}:</S.Label>
              <MachineLastSeen machine={machine} updatedAt={updatedAt} />
            </S.RightSectionItem>
          </S.RightSectionItems>
        </S.RightSection>
      </S.Details>
      <S.Divider />
      <TabBar>
        <Tab to={machineUrls.index(machine.id)} active={overviewActive}>
          {t`common.overview`}
        </Tab>
        <Tab to={machineUrls.thermals(machine.id)} active={thermalsActive}>
          {t`common.temperature`}
        </Tab>
        <Tab to={machineUrls.configuration(machine.id)} active={configActive}>
          {t`common.configuration`}
        </Tab>
        <Tab to={machineUrls.events(machine.id)} active={eventsActive}>
          {t`common.events`}
        </Tab>
      </TabBar>
    </S.Wrapper>
  );
};
