import styled from "styled-components";
import { Link } from "_/components/router";
import { transparentize } from "polished";

export const WhatsNewBox = styled.div`
  padding: 1rem 0.75rem 0.5rem 1rem;
  background-color: ${(p) => p.theme.background.tertiary};
  border-radius: 8px;
  font-size: 0.8125rem;
  & h3 {
    margin: 0;
    padding: 0 0 0.25rem 0;
    font-size: 1rem;
    line-height: 1.5rem;
  }
  & h4 {
    margin: 0;
    padding: 0.5rem 0 0 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem;
    color: ${(p) => p.theme.text.muted};
  }
  & ul {
    padding: 0.5rem 1rem;
    margin: 0;
  }
  & p {
    margin: 0;
    padding: 0.5rem 0;
    max-width: calc(100% - 1rem);
  }
`;

export const BlueLink = styled(Link)`
  color: ${(p) => p.theme.link.active};
  &:hover {
    color: ${(p) => p.theme.link.active};
    text-decoration: underline 1.5px
      ${(p) => transparentize(0.3, p.theme.link.active)};
  }
  &:active,
  &:visited {
    color: ${(p) => p.theme.link.active};
    &:hover {
      color: ${(p) => p.theme.link.active};
      text-decoration: underline 1.5px
        ${(p) => transparentize(0.3, p.theme.link.active)};
    }
  }
`;

// Marketing links that span multiple lines have a better UX with `display:
// block` because when hovering and moving between lines, the space between
// lines won't revert the hover for one pixel.
export const MarketingLink = styled(BlueLink)`
  display: block;
`;

export const SeeMore = styled.div`
  display: block;
  text-align: right;
  color: ${(p) => p.theme.text.muted};
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.text.default};
  }
  transition: color 0.15s ease;
  padding-bottom: 0.5rem;
  margin-top: -0.5rem;
`;

export const SeeMoreChevron = styled.span<{ expanded: boolean }>`
  display: inline-block;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  &::before {
    content: "⏷";
  }
  transform: rotate(${({ expanded }) => (expanded ? "180deg" : "0deg")});
  transition: transform 0.25s ease-in-out;
`;
