import styled from "styled-components";

const MARGIN = "10px";

export const Container = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  /* 
    Higher than React Query dev tools which is in the same place on the screen,
    and can obscure the toast close button.
   */
  z-index: 100000;

  > * {
    margin: ${MARGIN};
    margin-bottom: 0;

    &:last-of-type {
      margin-bottom: ${MARGIN};
    }
  }
`;
