import styled from "styled-components";

import { Icon } from "_/components/icon";
import { Link } from "_/components/router";

export const SearchInputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${Icon} {
    position: absolute;
    top: 0;
    left: 0.875rem;
    height: 100%;
    display: flex;
    align-items: center;
    color: ${(p) => p.theme.text.muted};
  }
`;

export const SearchInput = styled.input`
  display: block;
  flex-grow: 1;
  padding-left: 2.75rem;
  height: 2.125rem;

  border-right: none;
  border-radius: 2px;
  border: 1px solid ${(p) => p.theme.text.muted};

  font-size: 0.875rem;
  color: ${(p) => p.theme.text.default};
  background-color: transparent;
  outline: none;

  transition:
    border 0.15s,
    border-radius 0.15s;

  &:hover {
    background-color: ${(p) => p.theme.background.primary};
  }

  &:focus {
    border: 1px solid ${(p) => p.theme.input.focused.border};
  }
`;

export const SearchResults = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 100%;
  z-index: 2;

  max-height: 0;
  overflow-x: hidden;
  overflow-y: auto;

  background-color: ${(p) => p.theme.background.primary};
  border: 1px solid ${(p) => p.theme.input.default.border};
  border-radius: 0 0 2px 2px;
  border-top: none;

  transition: max-height 0.15s;
`;

export const SearchResultSection = styled.section`
  display: flex;
  flex-direction: column;

  scrollbar-width: auto;
  scrollbar-color: ${(p) => p.theme.header.background} transparent;

  &:not(:first-of-type) {
    border-top: 1px solid ${(p) => p.theme.divider.large};
  }

  &:first-of-type {
    padding-top: 0.25rem;
  }

  &:last-of-type {
    padding-bottom: 0.25rem;
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.5rem 0.25rem 1rem;
  cursor: default;

  color: ${(p) => p.theme.text.muted};

  ${Icon} {
    font-size: 24px;
  }
`;

export const SectionTitle = styled.span`
  font-weight: 600;
  font-size: 0.75rem;
`;

export const ResultTitle = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
`;

export const SectionResults = styled.div`
  display: grid;
`;

export const ResultItem = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: ${(p) => p.theme.background.secondary};

    & ${ResultTitle} {
      text-decoration: underline;
    }
  }

  &:focus {
    background-color: ${(p) => p.theme.background.tertiary};
  }
`;

export const NoResults = styled.div`
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  color: ${(p) => p.theme.text.muted};
`;

export const Muted = styled.span`
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: ${(p) => p.theme.text.muted};
`;

export const Highlight = styled.span`
  font-weight: 600;
  color: ${(p) => p.theme.link.default};
`;

export const Bar = styled.span`
  margin: 0 0.75em;
  color: ${(p) => p.theme.divider.small};

  &:before {
    content: "|";
  }
`;

export const Slash = styled.span`
  margin: 0 0.3125em;
  color: ${(p) => p.theme.divider.small};

  &:before {
    content: "/";
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  max-width: 668px;

  &:focus-within {
    ${SearchInput} {
      border-radius: 2px 2px 0 0;
    }

    ${SearchResults} {
      max-height: 80vh;
    }
  }
`;
