import React from "react";
import { useForm } from "react-hook-form";
import { t } from "@lingui/macro";
import { pick } from "ramda";

import { Button } from "_/components/button";
import { dateOnlyString } from "_/components/date-time";
import {
  Hardware,
  Machine,
  NewMachine,
  useCreateMachine,
  useRenameMachine,
} from "_/data/machines";
import {
  AdminMachineListing,
  MachineWithOrg,
} from "_/components/machine-listing";
import { SubmitCancelButtons, useModal } from "_/components/modal";
import { FormTextField } from "_/components/text-field";
import { FormSelect } from "_/components/select";
import { FormField } from "_/components/form-field";
import { PermissionTest, testPermissions } from "_/components/permission-test";
import { useToasts } from "_/components/toasts";
import { useLocation } from "_/components/router";

import { useTransferHardware } from "_/data/machines";
import { Org, useAon3dOrgs, useFactoryOrg, useOrgs } from "_/data/orgs";
import { ROOT_DOMAIN, useCurrentUserPermissions } from "_/data/rbac";

import { Uuid } from "_/types";
import { SERIAL_NUMBER_REGEX } from "_/utils/consts";
import { useSetLocationAndOrg } from "_/hooks/useSetLocationAndOrg";

import * as S from "./styled";
import { routeUrls } from "_/routes";
import { MachineRenameForm } from "_/components/admin-view";

/**
 * Form for editing machine name and hardware details.
 */
const MachineEditForm = ({
  machine,
  onClose,
}: {
  machine: Machine | undefined;
  onClose: () => void;
}) => {
  const renameMachine = useRenameMachine();
  const [_, addToast] = useToasts();
  const defaultValues = machine
    ? {
        ...pick(["name"], machine),
        ...pick(["serial", "manufactureDate"], machine.hardware),
      }
    : {
        name: "",
        serial: "",
        manufactureDate: dateOnlyString({
          value: new Date(),
          dateDelimiter: "-",
        }),
      };

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({ defaultValues });

  type FormData = Pick<Machine, "name"> &
    Pick<Hardware, "serial" | "manufactureDate">;

  const onSubmit = handleSubmit((data: FormData) => {
    if (!machine) {
      throw new Error("Machine data is missing (unreachable).");
    }

    renameMachine.mutateAsync({
      id: machine?.id,
      name: data.name,
      onError: ({ statusCode, message }) => {
        const msg = message || t`common.http-error-code ${statusCode}`;
        const title =
          statusCode === 403
            ? t`components.admin-view.action-permission-denied`
            : t`components.admin-view.machine-update-failed ${msg}`;
        addToast({
          title,
          kind: "warning",
          timeout: 5_000,
        });
      },
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <FormTextField
        control={control}
        name="serial"
        label={t`common.serial-number`}
        placeholder={t`common.serial-number`}
        rules={{
          required: t`components.admin-view.serial-number-required`,
          pattern: {
            value: SERIAL_NUMBER_REGEX,
            message: t`components.admin-view.serial-number-invalid`,
          },
        }}
      />
      <FormTextField
        control={control}
        name="name"
        label={t`common.name`}
        placeholder={t`common.name`}
        rules={{
          required: t`components.admin-view.name-required`,
          maxLength: {
            value: 255,
            message: t`components.admin-view.validate-machine-name-length`,
          },
        }}
      />
      <FormTextField
        control={control}
        name="manufactureDate"
        label={t`common.machine-manufacture-date`}
        placeholder={t`common.machine-manufacture-date`}
        type="date"
        rules={{
          required: t`components.admin-view.machine-manufacture-date-required`,
        }}
      />
      <SubmitCancelButtons onCancel={onClose} disableSubmit={!isDirty} />
    </form>
  );
};

/**
 * Form for creating a new machine, or editing existing machine details..
 */
const MachineCreateForm = ({
  onClose,
}: {
  machine?: Machine;
  onClose: () => void;
}) => {
  const createMachine = useCreateMachine();
  const factoryOrg = useFactoryOrg();
  const setLocationAndOrg = useSetLocationAndOrg();
  const [_, addToast] = useToasts();
  const defaultValues = {
    serial: "",
  };

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({ defaultValues });

  type FormData = Pick<Hardware, "serial">;

  const onSubmit = handleSubmit((data: FormData) => {
    if (!factoryOrg) {
      throw new Error("Factory org is required for machine creation.");
    }

    const newMachine: NewMachine = {
      ...data,
      orgId: factoryOrg.id,
    };

    createMachine.mutateAsync({
      newMachine,
      onSuccess: (machine) => {
        setLocationAndOrg({
          orgId: factoryOrg.id,
          url: routeUrls.machine.index(machine.id),
        });
      },
      onError: ({ statusCode, message }) => {
        const msg = message || t`common.http-error-code ${statusCode}`;
        const title =
          statusCode === 403
            ? t`components.admin-view.action-permission-denied`
            : t`components.admin-view.machine-creation-failed ${msg}`;
        addToast({
          title,
          kind: "warning",
          timeout: 5_000,
        });
      },
    });
    onClose();
  });

  return (
    <form onSubmit={onSubmit}>
      <FormTextField
        control={control}
        name="serial"
        label={t`common.serial-number`}
        placeholder={t`common.serial-number`}
        rules={{
          required: t`components.admin-view.serial-number-required`,
          pattern: {
            value: SERIAL_NUMBER_REGEX,
            message: t`components.admin-view.serial-number-invalid`,
          },
        }}
      />
      <SubmitCancelButtons onCancel={onClose} disableSubmit={!isDirty} />
    </form>
  );
};

/**
 * Form for transferring machine hardware from one organization to another.
 */
const HardwareTransferForm = ({
  machine,
  onClose,
  transferToOrgs,
}: {
  machine: Machine;
  onClose: () => void;
  transferToOrgs: Org[];
}) => {
  const transferHardware = useTransferHardware();
  const defaultOrgId =
    transferToOrgs.length === 1 ? transferToOrgs[0].id : undefined;
  const defaultValues = { orgId: defaultOrgId as Uuid | undefined };
  const { control, handleSubmit, formState } = useForm({ defaultValues });
  const [_toasts, addToast, _removeToasts] = useToasts();
  const [_, setLocation] = useLocation();

  if (!transferToOrgs.length) {
    return (
      <S.ErrorMessage>
        {t`components.admin-view.no-orgs-to-transfer-to`}
      </S.ErrorMessage>
    );
  }

  const onSubmit = handleSubmit(async (data) => {
    if (!data.orgId) {
      console.error("No org ID provided (unreachable).");
      return;
    }

    const targetOrg = transferToOrgs.find((o) => o.id === data.orgId);
    if (!targetOrg) {
      console.error("Target org not found (unreachable).");
      return;
    }

    await transferHardware.mutateAsync({
      machineId: machine.id,
      orgId: targetOrg.id,
      onSuccess: (machine) => {
        addToast({
          title: t`components.admin-view.transfer-toast.title`,
          kind: "success",
          content: t`components.admin-view.transfer-toast.content ${targetOrg.name}`,
          timeout: 10_000,
          actions: [
            {
              label: t`components.admin-view.transfer-toast.view-machine-label`,
              action: () => setLocation(routeUrls.machine.index(machine.id)),
            },
          ],
        });
      },
      onError: ({ statusCode, message }) => {
        const msg = message || t`common.http-error-code ${statusCode}`;
        const title =
          statusCode === 403
            ? t`components.admin-view.action-permission-denied`
            : t`components.admin-view.machine-transfer-failed ${msg}`;
        addToast({
          title,
          kind: "warning",
          timeout: 5_000,
        });
      },
    });

    onClose();
  });

  return (
    <form onSubmit={onSubmit}>
      <FormField label={t`common.serial-number`}>
        {machine.hardware.serial}
      </FormField>
      <FormField label={t`common.name`}>{machine.name}</FormField>
      <FormField label={t`common.machine-manufacture-date`}>
        {dateOnlyString({ value: machine.hardware.manufactureDate })}
      </FormField>
      {transferToOrgs.length > 1 ? (
        <FormSelect
          control={control}
          name="orgId"
          label={t`common.admin-view.transfer-destination-org`}
          options={transferToOrgs.map((o) => ({
            label: o.name,
            value: o.id,
          }))}
          required
        />
      ) : (
        <FormField label={t`common.admin-view.transfer-destination-org`}>
          {transferToOrgs[0].name}
        </FormField>
      )}
      <SubmitCancelButtons
        submitButtonLabel={t`components.admin-view.transfer-button-label`}
        onCancel={onClose}
        disableSubmit={!formState.isDirty && transferToOrgs.length > 1}
      />
    </form>
  );
};

type MachineListVariant = "factory" | "otherAon3d" | "customer" | "detached";

/**
 * One of the variations of machine lists in the factory tab.
 * Variations are:
 * - Factory machines - includes the ability to create new machines in the factory org,
 *   and can transfer to any other org.
 * - Other AON3D machines - can transfer to factory
 * - Customer machines - can transfer to factory
 */
export const FactoryTabMachineList = ({
  variant,
}: {
  variant: MachineListVariant;
}) => {
  const editModal = useModal<Machine>();
  const createModal = useModal<undefined>();
  const renameModal = useModal<Machine | undefined>();
  const transferModal = useModal<MachineWithOrg>();

  const { data: orgs } = useOrgs();
  const aon3dOrgs = useAon3dOrgs();
  const factoryOrg = useFactoryOrg();
  const factoryOrgArray = factoryOrg ? [factoryOrg] : [];
  const aon3dOrgIds = aon3dOrgs.map((o) => o.id);
  const otherAon3dOrgs = aon3dOrgs.filter((o) => o.id !== factoryOrg.id);
  const customerOrgs = orgs.filter((o) => !aon3dOrgIds.includes(o.id));
  const { data: permissions } = useCurrentUserPermissions(factoryOrg?.domainId);

  const variantInfo: {
    title: string;
    orgs: Org[];
    transferToOrgs?: Org[];
    transferLabel?: string;
    detached?: boolean;
  } = (
    {
      factory: {
        title: t`components.admin-view.factory-machines`,
        orgs: factoryOrgArray,
        transferToOrgs: orgs.filter((o) => o.id !== factoryOrg.id),
        transferLabel: t`components.admin-view.transfer-to-customer`,
      },
      otherAon3d: {
        title: t`components.admin-view.aon3d-machines`,
        orgs: otherAon3dOrgs,
        transferToOrgs: factoryOrgArray,
        transferLabel: t`components.admin-view.transfer-to-factory`,
      },
      customer: {
        title: t`components.admin-view.customer-machines`,
        orgs: customerOrgs,
        transferToOrgs: factoryOrgArray,
        transferLabel: t`components.admin-view.transfer-to-factory`,
      },
      detached: {
        title: t`common.detached-machines`,
        orgs: orgs,
        detached: true,
      },
    } as const
  )[variant];

  const canWriteProtected = testPermissions(
    "machines/writeProtected",
    permissions
  );
  const canWrite = testPermissions("machines/write", permissions);
  const canCreate = testPermissions("machines/create", permissions);
  const canTransfer = testPermissions("hardware/transfer", permissions);

  const actions = (machine: Machine) =>
    [
      canWrite && {
        render: t`common.rename`,
        onClick: () => renameModal.openModal(machine),
      },
      false && // hide until we have back-end support for editing hardware info
        canWriteProtected && {
          render: t`components.admin-view.edit-machine`,
          onClick: () => editModal.openModal(machine),
        },
      // Note that `canTransfer` is derived from permissions for the Factory org
      // only. In theory, it would be possible for someone to have transfer
      // permissions on Factory, but not on other orgs. In practice, the same
      // users who can transfer to/from Factory are likely to have this
      // permission in all orgs. But even if not (e.g. future manufacturing
      // users who have no access to customer orgs) we're still protected
      // because the server will block the action, even if they have to submit
      // the modal to learn that they don't have permissions.
      canTransfer &&
        variantInfo.transferToOrgs &&
        variantInfo.transferLabel && {
          render: variantInfo.transferLabel,
          onClick: () => transferModal.openModal(machine as MachineWithOrg),
        },
    ].filter(Boolean) as Array<{ render: string; onClick: () => void }>;

  return (
    <S.AdminContentSection>
      <S.AdminContentSectionTitleContainer>
        <h2>{variantInfo.title}</h2>
        {variant === "factory" && (
          <PermissionTest
            domainId={ROOT_DOMAIN}
            requiredPermissions={"machines/create"}
            render={({ allowed }) =>
              canCreate && (
                <Button
                  onClick={() => createModal.openModal(undefined)}
                  disabled={!allowed}
                >{t`components.admin-view.create-machine`}</Button>
              )
            }
          />
        )}
      </S.AdminContentSectionTitleContainer>
      <AdminMachineListing
        orgs={variantInfo.orgs}
        actions={actions}
        showOrg={variant !== "factory"}
        detached={variantInfo.detached}
      />
      <editModal.Modal
        title={
          editModal.modalData
            ? t`components.admin-view.rename-machine`
            : t`components.admin-view.create-machine`
        }
      >
        {editModal.isModalOpen && (
          <MachineEditForm
            onClose={editModal.closeModal}
            machine={editModal.modalData}
          />
        )}
      </editModal.Modal>
      <createModal.Modal
        title={
          createModal.modalData
            ? t`components.admin-view.rename-machine`
            : t`components.admin-view.create-machine`
        }
      >
        {createModal.isModalOpen && (
          <MachineCreateForm onClose={createModal.closeModal} />
        )}
      </createModal.Modal>
      <renameModal.Modal title={t`components.admin-view.rename-machine`}>
        {renameModal.modalData && (
          <MachineRenameForm
            onClose={renameModal.closeModal}
            machine={renameModal.modalData}
          />
        )}
      </renameModal.Modal>
      {variantInfo.transferToOrgs && (
        <transferModal.Modal title={t`components.admin-view.transfer-machine`}>
          {transferModal.modalData && (
            <HardwareTransferForm
              onClose={transferModal.closeModal}
              machine={transferModal.modalData}
              transferToOrgs={variantInfo.transferToOrgs}
            />
          )}
        </transferModal.Modal>
      )}
    </S.AdminContentSection>
  );
};

export const Factory = () => {
  return (
    <S.AdminContent>
      <FactoryTabMachineList variant="factory" />
      <FactoryTabMachineList variant="otherAon3d" />
      <FactoryTabMachineList variant="customer" />
      <FactoryTabMachineList variant="detached" />
    </S.AdminContent>
  );
};
