import styled from "styled-components";

import { Icon } from "_/components/icon";

import { ToastKind } from "./types";

const WIDTH = "430px";

export const Wrapper = styled.div`
  display: flex;

  background-color: ${(p) => p.theme.background.quaternary};
  width: ${WIDTH};
  padding: 1.5rem;
  border-radius: 4px;

  filter: drop-shadow(0px 0px 2px rgba(30, 33, 37, 0.2))
    drop-shadow(0px 2px 12px rgba(30, 33, 37, 0.1));
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

export const Title = styled.h3`
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  display: inline;
  color: ${(p) => p.theme.text.hover};
`;

export const Body = styled.div`
  color: ${(p) => p.theme.text.default};
  padding: 20px 0 12px;
`;

export const Content = styled.div`
  font-size: 14px;
  margin: 0;
  height: 100%;
`;

export const Actions = styled.div`
  display: flex;
  gap: 12px;

  justify-content: flex-end;
`;

export const KindIcon = styled(Icon)<{ $kind: ToastKind }>`
  font-size: 32px;

  color: ${(p) => p.theme.toast[p.$kind]};
`;

export const KindIconContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-right: 16px;
`;

export const CloseIcon = styled(Icon)`
  font-size: 20px;

  &:hover {
    color: ${(p) => p.theme.text.hover};
    cursor: pointer;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  place-self: center;
  place-content: center;
`;
