/**
 * Projects detail page header component.
 */

import React, { ReactElement, useState } from "react";
import {
  useRoute,
  useLocation,
  Redirect,
  NeverUnderlineSpace,
  TextLink,
} from "_/components/router";
import { t } from "@lingui/macro";

import {
  useDeleteProject,
  Project,
  Attempt,
  useProjectAttempts,
  useRevisionResources,
  Revision,
  useUpdateProject,
  Simulation,
  useProjectSimulations,
} from "_/data/projects";
import { blobDownloadURL } from "_/data/blob";

import { Button } from "_/components/button";
import { Select } from "_/components/select";
import { Icon } from "_/components/icon";
import { Modal } from "_/components/modal";
import { ProjectEditForm } from "_/components/project-forms";
import { OverlayMenu } from "_/components/overlay-menu";
import {
  DateTimeRange,
  dateTimeRangeString,
  dateTimeString,
} from "_/components/date-time";
import { Divider, Tab, TabBar } from "_/components/tab-bar";
import { Tooltip } from "_/components/tooltip";
import { projectRoutes, projectUrls, routeUrls } from "_/routes";

import { Uuid } from "_/types";

import * as S from "./styled";
import { StatusBadge } from "_/components/badge";

type PrepareInfoProps = {
  project: Project;
};

function PrepareInfo({ project }: PrepareInfoProps): ReactElement | null {
  const [_location, setLocation] = useLocation();
  const [_match, params] = useRoute(projectRoutes.prepare);

  const { id: projectId, revisions } = project;
  const revisionIdParam = params?.revisionId;

  // If the revision ID is not provided or is invalid, use the latest revision.
  const revision =
    revisions.find((r) => r.id === revisionIdParam) ?? revisions.at(-1);

  const { data: files = [] } = useRevisionResources(revision);

  if (!revision) return null;

  const gcodeFile = files.find((f) => f.kind === "gcode");

  const modifiedDateString = (r: Revision) =>
    t`common.modified ${dateTimeString({
      value: r.updatedAt ?? r.createdAt,
    })}`;

  // Generate revision options for the select component.
  const revOptions = revisions
    .map((r) => {
      // `label` for accessibility, `labelJsx` for usability
      const label = `${t`common.revision-name ${r.number}`} (${modifiedDateString(
        r
      )})`;
      const labelJsx = (
        <S.PickerOptionWrapper>
          <S.PrintOrRevisionNumberInSelect>
            #{r.number}
          </S.PrintOrRevisionNumberInSelect>{" "}
          {modifiedDateString(r)}
        </S.PickerOptionWrapper>
      );

      return { label, labelJsx, value: r.id };
    })
    .reverse(); // TODO: change to `toReversed` in 2025

  // If the revisionId is not specified, redirect to the latest revision.
  if (!revisionIdParam) {
    return (
      <Redirect
        to={projectUrls.prepare(projectId, revision.id)}
        replace={true}
      />
    );
  }

  return (
    <>
      <S.PickerLabel>{t`common.revision`}</S.PickerLabel>
      <S.RightSectionItems>
        <Select
          options={revOptions}
          value={revisionIdParam}
          onChange={(revisionId: Uuid) => {
            setLocation(projectUrls.prepare(projectId, revisionId));
          }}
          isSearchable={false}
        />
        {gcodeFile && (
          <S.FileDownloadLink
            href={blobDownloadURL(gcodeFile.id)}
            title={t`common.download-file ${gcodeFile.name}`}
          >
            G-code <Icon variant="FileDownload" />
          </S.FileDownloadLink>
        )}
      </S.RightSectionItems>
    </>
  );
}

type SimulationInfoProps = {
  /**
   * Project being viewed.
   */
  project: Project;

  /**
   * Simulations for the current project.
   */
  simulations?: Simulation[];
};

function SimulationInfo({
  project,
  simulations,
}: SimulationInfoProps): ReactElement | null {
  const [_match, params] = useRoute(projectRoutes.optimize);
  const [_location, setLocation] = useLocation();

  const lastSimulation = simulations?.[0];
  const projectId = project.id;
  const simulationIdParam = params?.simulationId;

  const getRedirectUrl = (simId: Uuid) => {
    const sim = simulations?.find((a) => a.job.id === simId);
    if (!sim) throw new Error("Simulation not found");
    return projectUrls.optimize(projectId, simId);
  };

  if (!simulations?.length) return null;

  // Invalid simulation ID. Redirect to the last valid simulation.
  if (lastSimulation && !simulationIdParam) {
    return (
      <Redirect to={getRedirectUrl(lastSimulation.job.id)} replace={true} />
    );
  }

  // Generate simulation options for the select component.
  const simulationOptions = simulations.map((simulation) => {
    const revision = project.revisions.find(
      (r) => r.id === simulation.revisionId
    );
    if (!revision) throw new Error("Revision not found");

    const simulationNumber = `#${revision.number}-S${simulation.number}`;

    const start = simulation.job.createdAt;
    const end = simulation.analysisOutput?.createdAt;

    // `label` for accessibility, `labelJsx` for usability
    const label = `${t`common.simulation-job`} ${simulationNumber} ${dateTimeRangeString(
      {
        start,
        end,
      }
    )}`;
    const labelJsx = (
      <S.PickerOptionWrapper>
        <S.PrintOrRevisionNumberInSelect>
          {simulationNumber}
        </S.PrintOrRevisionNumberInSelect>{" "}
        <DateTimeRange start={start} end={end} />
      </S.PickerOptionWrapper>
    );

    return { label, labelJsx, value: simulation.job.id };
  });

  return (
    <>
      <S.PickerLabel>{t`common.simulation-job`}</S.PickerLabel>
      <S.RightSectionItems>
        <Select
          options={simulationOptions}
          value={simulationIdParam}
          onChange={(v: Uuid) => setLocation(getRedirectUrl(v))}
          isSearchable={false}
        />
      </S.RightSectionItems>
    </>
  );
}

type AttemptInfoProps = {
  /**
   * Project being viewed.
   */
  project: Project;

  /**
   * Attempts for the current project.
   */
  attempts?: Attempt[];
};

function AttemptInfo({
  project,
  attempts,
}: AttemptInfoProps): ReactElement | null {
  const [_match, params] = useRoute(projectRoutes.analyze);
  const [_location, setLocation] = useLocation();

  const lastAttemptId = attempts?.[0]?.id;
  const projectId = project.id;
  const attemptIdParam = params?.attemptId;
  const attempt = attempts?.find((a) => a.id === attemptIdParam);
  const revision = project.revisions.find((r) => r.id === attempt?.revisionId);

  const { data: files = [] } = useRevisionResources(revision);

  if (!attempts?.length) return null;

  // Invalid attempt ID. Redirect to the last valid attempt.
  if (lastAttemptId && !attemptIdParam) {
    return (
      <Redirect
        to={projectUrls.analyze(projectId, lastAttemptId)}
        replace={true}
      />
    );
  }

  const gcodeFile = files.find((f) => f.kind === "gcode");

  // Generate attempt options for the select component.
  const attemptOptions = attempts.map((attempt) => {
    const revision = project.revisions.find((r) => r.id === attempt.revisionId);
    if (!revision) throw new Error("Revision not found");

    const revisionAttempts = attempts.filter(
      (a) => a.revisionId === revision.id
    );
    const attemptNumberInRevision =
      revisionAttempts.length - revisionAttempts.indexOf(attempt);
    const attemptNumber = `#${revision.number}-${attemptNumberInRevision}`;

    // `label` for accessibility, `labelJsx` for usability
    const label = `${t`common.print-job`} ${attemptNumber} ${dateTimeRangeString(
      {
        start: attempt.startedAt,
        end: attempt.endedAt,
      }
    )}`;
    const labelJsx = (
      <S.PickerOptionWrapper>
        <S.PrintOrRevisionNumberInSelect>
          {attemptNumber}
        </S.PrintOrRevisionNumberInSelect>{" "}
        <DateTimeRange start={attempt.startedAt} end={attempt.endedAt} />
      </S.PickerOptionWrapper>
    );

    return { label, labelJsx, value: attempt.id };
  });

  return (
    <>
      <S.PickerLabel>{t`common.print-job`}</S.PickerLabel>
      <S.RightSectionItems>
        <Select
          options={attemptOptions}
          value={attemptIdParam}
          onChange={(attemptId: Uuid) => {
            setLocation(projectUrls.analyze(projectId, attemptId));
          }}
          isSearchable={false}
        />
        {attempt && <StatusBadge status={attempt?.status} />}
        {revision && (
          <TextLink
            variant="muted"
            to={projectUrls.prepare(project.id, revision.id)}
          >
            <div>
              <span>{t`common.revision`}</span>
              <NeverUnderlineSpace />
              <S.RevisionNumber>#{revision.number}</S.RevisionNumber>
            </div>
          </TextLink>
        )}
        {gcodeFile && (
          <S.FileDownloadLink
            href={blobDownloadURL(gcodeFile.id)}
            title={t`common.download-file ${gcodeFile.name}`}
          >
            G-code <Icon variant="FileDownload" />
          </S.FileDownloadLink>
        )}
      </S.RightSectionItems>
    </>
  );
}

/**
 * Modal popup for when the user attempts to delete a project.
 */
function ProjectDeletionModal({
  isOpen,
  onClose,
  project,
}: {
  isOpen: boolean;
  project: Project;
  onClose: () => void;
}) {
  const [_location, setLocation] = useLocation();
  const deleteProject = useDeleteProject();

  async function execDelete() {
    setLocation(routeUrls.projects.index);
    await deleteProject.mutateAsync({ id: project.id });
  }

  const title = t`components.project-card.delete-modal-title`;
  const content = t`components.project-card.delete-modal-content ${project.name}`;

  return (
    <Modal title={title} open={isOpen} onClose={onClose}>
      <p>{content}</p>
      <S.DeleteModalButtons>
        <Button size="small" kind="danger" onClick={execDelete}>
          {t`common.delete`}
        </Button>
        <Button size="small" kind="secondary" onClick={onClose}>
          {t`common.cancel`}
        </Button>
      </S.DeleteModalButtons>
    </Modal>
  );
}

const ProjectMenu = ({
  project,
  attempts,
}: {
  project: Project;
  attempts: Attempt[];
}) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const updateProject = useUpdateProject();

  const [isUploading, setIsUploading] = useState(false);

  const archive = () => {
    updateProject.mutate({ ...project, status: "archived" });
  };
  const unarchive = () => {
    updateProject.mutate({ ...project, status: "active" });
  };

  return (
    <>
      <OverlayMenu
        target={
          <Button kind="secondary" size="small" icon>
            <Icon variant="MoreVert" />
          </Button>
        }
        placement="bottomStart"
        items={[
          {
            render: t`common.revise`,
            onClick: () => setShowEditModal(true),
          },
          {
            render:
              project.status === "archived"
                ? t`common.unarchive`
                : t`common.archive`,
            onClick: project.status === "archived" ? unarchive : archive,
          },
          {
            render: t`common.support`,
            onClick: () => {
              // Construct mailto for getting project specific support.
              const uri = [
                `mailto:help@aon3d.com`,
                `?subject=Basis Project Support [${project.id}]`,
              ].join("");
              window.open(encodeURI(uri));
            },
          },
          {
            render: () => {
              // Only show the tooltip if the project cannot be deleted.
              //
              // If this pattern becomes common throughout the codebase, it may be
              // desirable to add a `tooltip` prop for menu items to unify the
              // implementation.
              return attempts?.length ? (
                <Tooltip
                  wait={100}
                  delay={100}
                  placement="left"
                  target={t`common.delete`}
                  content={t`components.project-card.no-delete-tooltip`}
                  style={{ maxWidth: "240px" }}
                />
              ) : (
                <span>{t`common.delete`}</span>
              );
            },
            disabled: !!attempts?.length,
            onClick: () => setShowDeletionModal(true),
          },
        ]}
      />
      <ProjectDeletionModal
        isOpen={showDeletionModal}
        project={project}
        onClose={() => setShowDeletionModal(false)}
      />
      <Modal
        open={showEditModal}
        title={t`components.project-detail.revise-project`}
        onClose={isUploading ? undefined : () => setShowEditModal(false)}
      >
        <ProjectEditForm
          project={project}
          onComplete={() => setShowEditModal(false)}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
        />
      </Modal>
    </>
  );
};

type ProjectHeaderProps = {
  /**
   * Project to display header for.
   */
  project: Project;
};

export function ProjectHeader({ project }: ProjectHeaderProps): ReactElement {
  const [prepActive, _prepParams] = useRoute(projectRoutes.wildcards.prepare);
  const [optActive, _optParams] = useRoute(projectRoutes.wildcards.optimize);
  const [anaActive, _anaParams] = useRoute(projectRoutes.wildcards.analyze);
  const [histActive, _histParams] = useRoute(projectRoutes.history);

  const { data: attempts = [] } = useProjectAttempts(project.id);
  const { data: simulations = [] } = useProjectSimulations(project.id);

  // The entire component is wrapped in a ThemeProvider to override the set
  // style and always use the dark theme, regardless of the globally set
  // application theme. This can be safely removed if necessary and only
  // affects styles influenced by themes.
  return (
    <S.Wrapper>
      <S.LeftSection>
        <S.Details>
          <S.Meta>
            <S.Title>{project.name}</S.Title>
          </S.Meta>
          <S.Actions>
            <ProjectMenu project={project} attempts={attempts} />
          </S.Actions>
        </S.Details>
        <TabBar>
          <Tab to={projectUrls.prepare(project.id)} active={prepActive}>
            {t`components.project-header.prepare`}
          </Tab>
          <Tab to={projectUrls.optimize(project.id)} active={optActive}>
            {t`components.project-header.optimize`}
          </Tab>
          <Tab to={projectUrls.analyze(project.id)} active={anaActive}>
            {t`components.project-header.analyze`}
          </Tab>
          <Divider />
          <Tab to={projectUrls.history(project.id)} active={histActive}>
            {t`components.project-header.history`}
          </Tab>
        </TabBar>
      </S.LeftSection>
      <S.RightSection>
        {prepActive && <PrepareInfo project={project} />}
        {optActive && (
          <SimulationInfo project={project} simulations={simulations} />
        )}
        {anaActive && <AttemptInfo project={project} attempts={attempts} />}
      </S.RightSection>
    </S.Wrapper>
  );
}
