import React, {
  ReactElement,
  useCallback,
  useMemo,
  forwardRef,
  ReactNode,
  Ref,
} from "react";
import { t } from "@lingui/macro";
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  InputProps,
  Props,
  MultiValue,
  SingleValue,
  ClearIndicatorProps,
  SelectInstance,
  SingleValueProps,
  MultiValueGenericProps,
} from "react-select";
export type { StylesConfig } from "react-select";
import {
  RegisterOptions,
  Controller,
  Control,
  FieldValues,
  FieldPath,
} from "react-hook-form";
import { equals, find } from "ramda";

import { FormField, FormFieldProps } from "_/components/form-field";
import { Icon } from "_/components/icon";

import * as S from "./styled";

/**
 * Options used in the select must contain at minimum a label and value which
 * can be rendered. They may extend this and include additional fields which
 * can be used by a custom renderer.
 *
 * For most simple use cases where no additional custom rendering is required
 * using option data, it is sufficient to use the Option interface as a direct
 * type.
 */
export interface Option<D = string> {
  /**
   * Text to be displayed for option in `<Select />` component
   */
  label: string;

  /**
   * Optional JSX rendered in place of the label text. `label` is still
   * required for accessibility.
   */
  labelJsx?: ReactNode;

  /**
   * Optional JSX rendered in place of the value label text. */
  selectedValueJsx?: ReactNode;

  /**
   * Operative value of option in `<Select />` component.
   *
   * This value will be used as the underlying data if no `data` field is provided.
   */
  value: string;

  /**
   * Underlying data associated with the option which will be used in `onChange`
   * events if present. If this is omitted, the `value` will be used instead.
   */
  data?: D;
}

export type SelectProps<
  Opt extends Option<D>,
  IsMulti extends boolean = false,
  Group extends GroupBase<Opt> = GroupBase<Opt>,
  D = Opt["data"],
> = FormFieldProps &
  Omit<
    Props<Opt, IsMulti, Group>,
    | "options"
    | "theme"
    | "value"
    | "defaultValue"
    | "isMulti"
    | "isDisabled"
    | "onChange"
    | "classNames"
    | "className"
  > & {
    options: Opt[];

    /**
     * Select value, or a list of multiple values.
     *
     * This will change the required signature of the onChange handler, so if a
     * select is required to work with both single and multiple values it is best to
     * wrap the single value in `[]`, to simplify the onChange handler.
     */
    value?: IsMulti extends true ? MultiValue<D> : SingleValue<D>;

    /**
     * Default value for the select component.
     */
    defaultValue?: IsMulti extends true ? MultiValue<D> : SingleValue<D>;

    /**
     * Direct prop mapping to the react-select `isMulti` prop.
     *
     * This is overridden in order to preserve native `<select />` attribute
     * usage as much as possible and defaults to false.
     */
    multiple?: boolean;

    /**
     * Direct prop mapping to the react-select `isDisabled` prop.
     *
     * This is overridden in order to preserve native `<select />` attribute
     * usage as much as possible and defaults to false.
     */
    disabled?: boolean;

    /**
     * Sets styling of `<Select />` to warn that the current state is invalid
     */
    invalid?: boolean;

    /**
     * Overrides the `onChange(...)` callback provided by `react-select` to provide
     * an interface similar to the HTML `<select />` element
     */
    onChange?: (
      newValue: (IsMulti extends true ? Opt["data"][] : Opt["data"]) | null
    ) => void;
  };

const formatOptionLabel = ({
  label,
  labelJsx,
}: {
  label: string;
  labelJsx?: ReactNode;
}) => labelJsx ?? label;

const DropdownIndicator = <Opt,>(props: DropdownIndicatorProps<Opt>) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon variant="ArrowDropDown" label="select-dropdown" />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = <Opt,>(props: ClearIndicatorProps<Opt>) => {
  return (
    <components.ClearIndicator {...props}>
      <Icon variant="Close" label="select-clear" />
    </components.ClearIndicator>
  );
};

const Input = <Opt,>(props: InputProps<Opt>) => {
  return <components.Input {...props} aria-label="select-input" />;
};

function _Select<
  Opt extends Option<D>,
  IsMulti extends boolean = false,
  D = Opt["data"],
>(
  props: SelectProps<Opt, IsMulti>,
  ref: Ref<SelectInstance<Opt, IsMulti>> | undefined
): ReactElement {
  const {
    invalid,
    options,
    value,
    defaultValue,
    multiple,
    disabled,
    onChange,
    label,
    hint,
    help,
    className,
    placeholder,
    ...restProps
  } = props;

  // Helper function to find the selected options in the list of options using
  // the set `value` or `defaultValue`.
  const findSelected = useCallback(
    (opt: Opt, val: SelectProps<Opt, IsMulti>["value"]) => {
      // Data values are being used, so we find the option value using the data.
      if (opt.data !== undefined) {
        return equals(opt.data, val as D);
      }

      return equals(opt.value, val as string);
    },
    []
  );

  // Reconstruct the default value for react-select from the passed in value.
  //
  // If there is no specified value prop, the component is not a controlled
  // component so we allow for `<ReactSelect />` to manage state and set the
  // value prop to `undefined`.
  const setValue = useMemo(() => {
    if (value === undefined) {
      return undefined;
    }

    if (Array.isArray(value)) {
      return value.reduce((acc, val) => {
        const v = find((opt) => findSelected(opt, val), options);
        acc.push(v);
        return acc;
      }, [] as Opt[]);
    }

    return options.find((opt) => findSelected(opt, value));
  }, [value, options, findSelected]);

  // Reconstruct the default value for react-select from the passed in value.
  const setDefaultValue = useMemo(() => {
    return options.find((opt) => findSelected(opt, defaultValue));
  }, [defaultValue, options, findSelected]);

  // The "className" and "classNamePrefix" props on <ReactSelect /> are used to
  // facilitate styling with styled-components.
  //
  // "classNamePrefix" sets a human-readable string to prefix all classes
  // targeting <ReactSelect />'s subcomponents and special states of those
  // subcomponents.
  const select = (
    <S.Wrapper $invalid={invalid} className={className}>
      <ReactSelect
        {...restProps}
        ref={ref}
        className="react-select-container"
        classNamePrefix="react-select"
        components={{
          Input,
          ClearIndicator,
          DropdownIndicator,
          IndicatorSeparator: () => null,
          SingleValue: (props: SingleValueProps<Opt>) => {
            const { label, labelJsx, selectedValueJsx } = props.data;
            return (
              <components.SingleValue {...props}>
                {selectedValueJsx ?? labelJsx ?? label}
              </components.SingleValue>
            );
          },
          MultiValueLabel: (props: MultiValueGenericProps<Opt>) => {
            const { label, labelJsx, selectedValueJsx } = props.data;
            return (
              <components.MultiValueLabel {...props}>
                {selectedValueJsx ?? labelJsx ?? label}
              </components.MultiValueLabel>
            );
          },
        }}
        options={options}
        placeholder={placeholder ?? t`common.select-placeholder`}
        defaultValue={setDefaultValue}
        value={setValue}
        isMulti={multiple}
        isDisabled={disabled}
        onChange={(change: MultiValue<Opt> | SingleValue<Opt>) => {
          if (change === null) {
            return onChange?.(null);
          }

          const getChangeValue = (opt: Opt) => {
            return opt.data !== undefined ? opt.data : opt.value;
          };

          const onChangeArg = Array.isArray(change)
            ? (change as MultiValue<Opt>).map(getChangeValue)
            : getChangeValue(change as Opt);

          // A cast is needed below because TS isn't smart enough to know what
          // `IsMulti` must be depending on whether `change` is an array or not.
          type OnChangeArg = IsMulti extends true ? Opt["data"][] : Opt["data"];
          onChange?.(onChangeArg as OnChangeArg);
        }}
        formatOptionLabel={formatOptionLabel}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
      />
    </S.Wrapper>
  );

  return (
    <FormField label={label} invalid={invalid} help={help} hint={hint}>
      {select}
    </FormField>
  );
}

export const Select = forwardRef(_Select);

interface FormSelectProps<
  T extends FieldValues,
  K extends FieldPath<T>,
  Opt extends Option<D>,
  IsMulti extends boolean = false,
  D = Opt["data"],
> extends Omit<SelectProps<Opt, IsMulti>, "invalid" | "name" | "hint"> {
  control: Control<T, K>;
  name: K;
  rules?: RegisterOptions<T, K>;
}

export function FormSelect<
  T extends FieldValues,
  K extends FieldPath<T>,
  Opt extends Option<D>,
  IsMulti extends boolean = false,
  D = Opt["data"],
>(props: FormSelectProps<T, K, Opt, IsMulti>): ReactElement {
  const { control, name, rules, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <Select
            {...field}
            {...rest}
            invalid={fieldState.invalid}
            hint={fieldState.error?.message}
          />
        );
      }}
    />
  );
}
